import lang from "ra-language-english";

import errorCode from "../../utils/errorCode.json";

const json = {
  ra: lang.ra,
  common: {
    "": "",
    enter_yubi_key: "请输入YubiKey",
    approval: "审批",
    approve: "审批",
    cancel: "取消",
    confirm: "确定",
    download: "下載",
    edit: "编辑",
    reject: "拒绝",
    reset: "重置",
    search: "查询",
    sign: "签署",
    status: "状态",
    submit: "提交",
    save: "保存",
    view: "查看",
    export: "导出",
    table: "列表",
    start_date: "开始时间",
    end_date: "结束时间",
    date_hr_start_date: "开始日期",
    date_hr_end_date: "结束日期",
    no_data_export: "没有数据导出",
    copied: "已复制！",
    yes: "是",
    no: "否",
    true: "正确",
    false: "错误",
    alert_creator_approver_same: "操作人和创建人不能是同一个人",
    alert_approvers_same: "审批人不能相同",
    alert_no_approval_permission: "没有相关权限",
    alert_no_permission: "没有相关权限",
    input_hours_only_warning: "请只输入小时数",
    please_click_search: "请点击”查询”搜索记录",
    date: "日期",
    time: "时间",
    column_hidden_message: "有些列被隐藏",
    columnMenuShowColumns: "显示列",
    columnMenuHideColumn: "隐藏",
    columnsPanelTextFieldLabel: "查找列",
    columnsPanelTextFieldPlaceholder: "列标题",
    columnsPanelShowAllButton: "全部显示",
    columnsPanelHideAllButton: "全部隐藏",
  },
  general: {
    autoLogoutAlert: "自动登出，因为另一个帐户已从同一浏览器登录",
    logoutAlert: "您的帐户已登出",
  },
  yubiKeyHook: {
    please_enter: "请输入",
    ph_yubikey: "YubiKey OTP",
    ph_remarks: "备注 (非必填)",
  },
  accountSetting: {
    lang: "语言",
    email: "登录电邮",
    yubikey_status: "YubiKey 状态",
    timezone: "时区",
    language: "语言",
  },
  config: {
    noTokenError: "No Token",
    invalidTokenError: "Invalid token",
    redirecting: "页面跳转中",
    login: "登入",
    assetNotSupported: "不支持此货币",
  },
  component: {
    typeToSearch: "输入并搜索",
    noRecords: "没有相关记录",
    itemTotalStart: "共有 ",
    itemTotalEnd: " 项资料",
    merchantWithdrawStatus: "商户取款状态",
    disable: "停用",
  },
  menu: {
    homepage: "",
    account_setting: "账户设置",
    walletManager: "Wallet Manager",
    batch_sweep: "批量扫币",
    withdraw_request_record: "取款申请记录",
    batch_withdraw: "批量取款",
    sweep_request_record: "扫币申请记录",
    client_wallet_management: "用户钱包管理",
    clientWallet: "用户钱包管理",
    wallet_balance_adjustment: "钱包余额调整",
    merchant_wallet_management: "商户钱包管理",
    wallet_management: "钱包管理",
    wallet_balance: "钱包余额",
    merchant_report: "商户报表",
    client_report: "用户报表",
    logout: "登出",
    dashboard: "Dashboard",
    message_management: "讯息管理",
    operation_records: "操作记录",
    operation_transaction_records: "操作交易记录",
    deposit_and_withdrawal_statistics: "存取款统计",
    transaction_report: "全部交易",
    merchant_operation: "商户操作",
    withdraw_setting: "取款设置",
    withdraw_approval_setting: "取款审批设置",
    withdraw_approval_checking_time_setting: "取款审批检查时间设置",
    assetManagement: "资产管理",
    walletManagement: "钱包管理",
    report: "报表",
    tools: "工具",
    callback_setting: "回调设定",
    welcome: "欢迎",
    total_wallet_balance: "总余额概览",
    block_rescan: "重扫",
    re_broadcast: "重新广播",
    yubi_key_register: "YubiKey 注册",
    yubi_key_management: "YubiKey 管理",
    user_approval_management: "用户审批管理",
    balance_checkpoint: "余额检查点",
    nonce_tool: "Nonce 工具",
    audit_log: "审计日志",
    asset_list: "货币列表",
    Sweep: "扫币",
    address_generation: "地址生成(离线)",
    address_book: "地址簿",
    version: "版本",
    utxo_tool_management: "UTXO 工具",
    ledger: "Ledger",
    wallet_manager_deposit_record: "存款记录",
    withdraw_request: "取款申请",
    transfer_request: "转账申请",
    client_management: "用户管理",
    client_balance: "用户余额",
    ledger_transaction_record: "Ledger交易记录",
    re_submit: "重新提交",
    ledger_freeze_request: "冻结余额申请",
    ledger_frozen_fund: "冻结资金记录",
  },

  ledgerClientBalance: {
    creation_time: "创建时间",
    last_modified_date: "最后更新时间",
    chain_name: "链名称",
    currency: "货币",
    client_id: "用户ID",
    ph_chain_name: "请选择链名称",
    ph_currency: "请选择货币",
    ph_client_id: "请输入用户ID",
    client_balance: "用户余额",
    exclude_zero: "不包括零",
    cb_both_fields_in_client_balance_are_required:
      "用户余额中的两个字段均为必填项",
    cb_should_not_be_zero_as_zero_is_excluded:
      "用户余额不应为零，因为零已被排除",
    ph_client_balance: "请输入用户余额",
    frozen_balance: "冻结余额",
    credit: "信用额度",
  },
  ledgerClientManagement: {
    creation_time: "创建时间",
    last_modified_date: "最后更新时间",
    chain_name: "链名称",
    client_type: "用户类型",
    withdrawable: "可取款",
    transferable: "可转账",
    allow_credit_setting: "可设置信用额度",
    disabled_client: "已禁用用户",
    ph_withdrawable: "可取款用户",
    ph_transferable: "可转账用户",
    ph_allow_credit_setting: "可设置信用额度",
    ph_disabled_client: "已禁用用户",
    operation: "操作",
    ph_client_type: "请选择用户类型",
    currency: "货币",
    client_id: "用户ID",
    system: "SYSTEM",
    house: "HOUSE",
    client: "CLIENT",
    ph_chain_name: "请选择链名称",
    ph_currency: "请选择货币",
    ph_client_id: "请输入用户ID",
    balance: "钱包余额",
    frozen_balance: "冻结余额",
    credit: "信用额度",
    yes: "是",
    no: "否",
    edit: "编辑",
    editClient: "编辑用户",
    addClient: "添加用户",
    addNewClient: "添加新用户",
    editError: "值没有变动，请修改",
    addErrorId: "请输入用户ID",
    addErrorType: "请选择用户类型",
    successAdd: "已成功添加新用户",
    successEdit: "已成功修改",
  },

  ledgerTransactionRecord: {
    creation_time: "创建时间",
    direction: "方向",
    transaction_type: "交易类型",
    client_id: "用户ID",
    sending_client_id: "发送用户ID",
    receiving_client_id: "接收用户ID",
    chain_name: "链名称",
    currency: "货币",
    original_order_id: "原始订单ID",
    merchant_order_id: "商户订单ID",
    ledger_transaction_id: "Ledger交易ID",
    ph_direction: "请选择方向",
    ph_transaction_type: "请选择交易类型",
    ph_client_id: "请输入用户ID",
    ph_sending_client_id: "请输入发送用户ID",
    ph_receiving_client_id: "请输入接收用户ID",
    ph_chain_name: "请选择链名称",
    ph_currency: "请选择货币",
    ph_original_order_id: "请输入原始订单ID",
    ph_merchant_order_id: "请输入商户订单ID",
    ph_ledger_transaction_id: "请输入Ledger交易ID",
    sending_wallet_address: "发送地址",
    receiving_wallet_address: "接收地址",
    transaction_amount: "交易金额",
    post_balance: "交易后余额",
    freeze_amount: "冻结金额",
    post_frozen_balance: "剩余冻结余额",
    remarks: "备注",
    operation: "操作",
    resend: "重新发送",
    resendSuccess: "重新发送成功",
  },
  ledgerWalletManagerDepositRecord: {
    creation_time: "创建时间",
    last_modified_time: "最后更新时间",
    operation: "操作",
    chain_name: "链名称",
    ph_chain_name: "请选择链名称",
    currency: "货币",
    ph_currency: "请选择货币",
    approval_progress: "审批进度",
    client_id: "用户ID",
    ph_client_id: "请输入用户ID",
    transaction_amount: "交易金额",
    transaction_status: "交易状态",
    ph_transaction_status: "请选择交易状态",
    request_status: "请求状态",
    ph_request_status: "请选择请求状态",
    reference_no: "参考编号",
    ph_reference_no: "请输入参考编号",
    wallet_address: "钱包地址",
    ph_wallet_address: "请输入钱包地址",
    sending_address: "发送地址",
    ph_sending_address: "请输入发送地址",
    wallet_tag: "钱包标签",
    risk_level: "风险等级",
    ph_risk_level: "请选择风险等级",
    transaction_hash: "交易Hash",
    ph_transaction_hash: "请输入交易Hash",
    auto_approve: "自动",
    block_no: "区块号",
    block_hash: "区块Hash",
    block_time: "区块交易时间",
    on_chain_transaction_status: "链上交易状态",
    subtitle_1st_box: "交易通用信息",
    subtitle_2nd_box: "交易发送/接收地址信息",
    subtitle_3rd_box: "区块链信息",
    subtitle_4th_box: "切换手动审批流程",
    subtitle_5th_box: "审批操作",
    switched_by: "切换人",
    switched_time: "切换时间",
    switching_remarks: "切换备注",
    showAllRecord: "全部",
    showToDoRecord: "待办事项",
    showCompleted: "已完成事项",
    viewBtn: "查看",
    approveBtn: "审批/拒绝",
    approval: "审批",
    switch_to_manual_approval_process: "切换手动审批流程",
    approve_success: "批准成功",
    reject_success: "拒绝成功",
    switch_success: "切换成功",
    approval_sequence: "审批顺序",
    operation_result: "操作结果",
    remarks: "备注",
    operated_by: "操作人",
    operation_time: "操作时间",
    approval_level_1: "第一",
    approval_level_2: "第二",
    approval_level_3: "第三",
    approval_level_4: "第四",
    switch: "切换手动审批",
  },
  ledgerWithdrawRequest: {
    creation_time: "创建时间",
    last_modified_time: "最后更新时间",
    chain_name: "链名称",
    currency: "货币",
    receiving_address: "接收地址",
    request_status: "请求状态",
    wm_operation_status: "WM操作状态",
    merchant_order_id: "商户订单ID",
    refund_order_id: "退款订单ID",
    transaction_hash: "交易Hash",
    creator_type: "创建类型",
    ph_chain_name: "请选择链名称",
    ph_currency: "请选择货币",
    ph_sending_address: "请输入发送地址",
    ph_receiving_address: "请输入接收地址",
    ph_request_status: "请选择请求状态",
    ph_wm_operation_status: "请选择WM操作状态",
    ph_merchant_order_id: "请输入商户订单ID",
    ph_refund_order_id: "请输入退款订单ID",
    ph_transaction_hash: "请输入交易Hash",
    ph_creator_type: "请选择创建类型",
    operation: "操作",
    client_id: "用户ID",
    transaction_amount: "交易金额",
    approval_progress: "审批进度",
    auto_approve: "自动",
    amount_of_fee: "矿工费金额",
    currency_of_fee: "矿工费币种",
    created_by: "创建人",
    sending_address: "发送地址",
    hot_wallet_address: "热钱包地址",
    wallet_tag_of_receiving_address: "接收地址钱包标签",
    create_request: "创建申请",
    ph_wallet_tag_of_receiving_address: "请输入接收地址钱包标签",
    approveBtn: "审批/拒绝",
    approval: "审批",
    ph_client_id: "请输入用户ID",
    ph_transaction_amount: "请输入交易金额",
    approve_success: "批准成功",
    reject_success: "拒绝成功",
    success_create: "成功创建取款申请",
    approval_sequence: "审批顺序",
    operation_result: "操作结果",
    remarks: "备注",
    operated_by: "操作人",
    operation_time: "操作时间",
    create_warning: "请%{verb}%{field}",
    select: "选择",
    enter: "输入",
    asset_name: "货币",
    approval_level_1: "第一",
    approval_level_2: "第二",
    approval_level_3: "第三",
    approval_level_4: "第四",
    available_balance: "可取款金额: %{balance} %{assetName}",
    available_client_balance: "用户可取款金额: %{balance} %{assetName}",
    must_be_larger_than_zero: "%{fieldName}必须大于 0",
  },
  ledgerFreezeRequest: {
    merchant_order_id: "商户订单ID",
    approvals: "审批",
    client_id: "用户ID",
    chain_name: "链名称",
    currency: "货币",
    amount: "金额",
    seq: "顺序",
    frozen_order_id: "冻结余额订单ID",
    related_order_id: "相关订单ID",
    status: "状态",
    created_by: "创建人",
    creator_type: "创建类型",
    creation_time: "创建时间",
    last_modified_time: "最后更新时间",
    remarks: "备注",
    error_reason: "错误原因",
    ph_frozen_order_id: "请输入冻结余额订单ID",
    ph_related_order_id: "请输入相关订单ID",
    ph_client_id: "请输入用户ID",
    ph_status: "请选择状态",
    ph_merchant_order_id: "请输入商户订单ID",
    ph_chain_name: "请选择链名称",
    ph_currency: "请选择货币",
  },
  ledgerFrozenFund: {
    creation_time: "创建时间",
    last_modified_time: "最后更新时间",
    frozen_time: "冻结余额时间",
    unfrozen_time: "解冻余额时间",
    chain_name: "链名称",
    currency: "货币",
    merchant_order_id: "商户订单ID",
    status: "状态",
    client_id: "用户ID",
    ph_status: "请选择状态",
    ph_merchant_order_id: "请输入商户订单ID",
    ph_chain_name: "请选择链名称",
    ph_currency: "请选择货币",
    ph_client_id: "请输入用户ID",
    frozen_amount: "金额",
    frozen_date: "冻结日期",
    unfrozen_date: "解冻日期",
    Frozen: "已冻结",
    Unfrozen: "已解冻",
  },
  ledgerTransferRequest: {
    creation_time: "创建时间",
    last_modified_time: "最后更新时间",
    chain_name: "链名称",
    currency: "货币",
    request_status: "请求状态",
    merchant_order_id: "商户订单ID",
    creator_type: "创建类型",
    ph_chain_name: "请选择链名称",
    ph_currency: "请选择货币",
    ph_request_status: "请选择请求状态",
    ph_merchant_order_id: "请输入商户订单ID",
    ph_creator_type: "请选择创建类型",
    operation: "操作",
    sending_client_id: "发送用户ID",
    receiving_client_id: "接收用户ID",
    ph_sending_client_id: "请输入发送用户ID",
    ph_receiving_client_id: "请输入接收用户ID",
    transaction_amount: "交易金额",
    approval_progress: "审批进度",
    auto_approve: "自动",
    amount_of_fee: "矿工费金额",
    currency_of_fee: "矿工费币种",
    created_by: "创建人",
    remarks: "备注",
    create_request: "创建申请",
    ph_transaction_amount: "请输入交易金额",
    create_warning: "请%{verb}%{field}",
    select: "选择",
    enter: "输入",
    asset_name: "货币",
    approveBtn: "审批/拒绝",
    approval: "审批",
    approve_success: "批准成功",
    reject_success: "拒绝成功",
    success_create: "成功创建转账申请",
    approval_sequence: "审批顺序",
    operation_result: "操作结果",
    operated_by: "操作人",
    operation_time: "操作时间",
    approval_level_1: "第一",
    approval_level_2: "第二",
    approval_level_3: "第三",
    approval_level_4: "第四",
    receiving_and_sending_client_same: "发送客户ID 和接收客户ID不能相同",
    must_be_larger_than_zero: "%{fieldName}必须大于 0",
  },

  withdrawApprovalSetting: {
    add_item: "添加项目",
    add_record: "添加记录",
    after_amt: "修改后",
    alert_creator_approver_same: "操作人和创建人不能是同一个人",
    alert_no_approval_permission: "没有相关权限",
    amountDidntChange: "金额没有变动，请修改金额",
    amountIslargerThan: "当总金额大于",
    approveOrReject: "审批/拒绝",
    asset_name: "货币",
    assetIsEmpty: "请选择货币",
    approval_status: "审批状态",
    batch_id: "批次编号",
    before_amt: "修改前",
    chain_asset: "货币",
    chain_name: "链名称",
    chainNameIsEmpty: "请选择链名称",
    clickToApprove: "审批",
    ConfirmApprove: "审批成功",
    ConfirmReject: "已拒绝请求",
    created_by: "创建人",
    created_date: "创建时间",
    creation_time: "创建时间",
    edit_record: "编辑记录",
    edit_request_created: "已生成修改请求",
    from_address: "发送地址",
    hourlyLimistMustBeGreaterThanAmtInRegulation:
      "%{time}小时内取款自动审批限额“的值必须等于或大于审批规则中的金额",
    last_modified_date: "最后更新时间",
    limit_inputBox_description: "如果没有限制，则无需输入",
    max_hourly_approve_limit: "%{time}小时内自动审批限额",
    maximumOfApprovers: "审批者的最大数量为 4",
    mx_addr_hrly_count: "接收地址 %{time}小时内最大交易笔数",
    mx_addr_hrly_limit: "接收地址 %{time}小时内最高交易总额",
    mx_hourly_limit: "%{time}小时内审批总限额",
    noOfApprover: "审批者数量",
    onlyAllowDeleteTheLargesNo: "请先移除审批者数量最多的项目",
    operated_by: "操作人",
    operation_time: "操作时间",
    operation: "操作",
    ph_settle: "非必填",
    phApproval_status: "请选择状态",
    phAsset_name: "请选择货币",
    phAutoApproval_limit: "請輸入%{time}小时内自动审批限额（非必填）",
    phChain_name: "请选择链名称",
    phMx_addr_hrly_count: "请输入接收地址%{time}小时内最大交易笔数（非必填）",
    phMx_addr_hrly_limit: "请输入接收地址%{time}小时内最高交易总额（非必填）",
    phMx_hourly_limit: "请输入%{time}小时内审批总限额（非必填）",
    phProposal_no: "请输入提案号（可多选）",
    pleaseEnterSettleMentAmt: "请在审批规则中输入结算限额",
    pleaseEnterWithDrawAmt: "请在审批规则中输入取款限额",
    pleaseEnterWithdrawAmtAndSettlementAmt:
      "请在审批规则中输入取款限额和结算限额",
    previousSettleLimitIsEmpty: "请在审批规则中输入上一行结算限额",
    previousWithdrawLimitIsEmpty: "请在审批规则中输入上一行取款限额",
    proposal_no: "提案号",
    recordExist: "已存在相关记录",
    regulation: "审批规则",
    rejectQuestion: "确定拒绝此审批?",
    reqEditMaxLimitWithin1Hr: "请求修改%{time}小时内审批总限额",
    reqEditMaxNoTrxAmt: "请求修改%{time}小时内最高交易总额",
    reqEditMaxNoTrxs: "请求修改%{time}小时内最大交易笔数",
    request_hourly_limit: "请求修改%{time}小时内自动审批限额",
    request_record: "请求记录",
    rescan: "重扫",
    resendSuccess: "重新发送成功",
    settleAmtMustBeGreater: "请在审批规则中输入大于上一行的结算限额",
    settlement: "结算",
    status: "状态",
    total_amount: "总金额",
    total_count: "总数量",
    type: "类型",
    typeToSearch: "输入并搜索",
    view: "查看",
    view_operation: "查看操作",
    view_operation_transaction: "查看操作交易",
    withdraw_approval_setting: "取款审批设置",
    withdraw: "取款",
    withdrawAmtMustBeGreater: "请在审批规则中输入大于上一行的取款限额",
    withDrawApprovalSettingDialog: "审批设置",
  },
  withdrawApprovalCheckingTimeSetting: {
    checkingTimeSettingTab: "检查时间设置",
    requestRecordTab: "请求记录",
    existingCheckingTime: "现有检查时间",
    checkingTime: "检查时间",
    phCheckingTime: "请输入新的检查时间",
    hasPendingRequest: "当前有未审批的请求",

    limited24Hours: "最长检查时间为24小时",
    no_approval_permission: "没有相关权限",

    edit: "编辑",
    hour: "小时",
    hours: "小时",

    operation: "操作",
    proposal_no: "提案编号",
    approval_progress: "审批进度",
    before_edit: "编辑前",
    after_edit: "编辑后",
    created_by: "创建人",
    creation_time: "创建时间",
    operated_by: "操作者",
    operation_time: "操作时间",

    approve: "审批",
    approval: "审批",
    reject: "拒绝",
    approve_reject_btn: "审批/拒绝",
    approval_sequence: "审批顺序",
    operation_result: "操作结果",

    approval_status: "审批状态",
    phProposal_no: "请输入提案编号。",
    phApproval_status: "请选择审批状态",
    approve_success: "批准成功",
    reject_success: "拒绝成功",
    success: "成功",
    alert_creator_approver_same: "操作人和创建人不能是同一个人",
    alert_approvers_same: "审批人不能相同",
    alert_no_approval_permission: "没有相关权限",
  },

  withdrawRequestRecord: {
    startDate: "开始时间",
    endDate: "结束时间",
    created_date: "创建时间",
    status: "状态",
    chain_name: "链名称",
    asset_name: "货币",
    batch_id: "批次编号",
    merchant_order_id: "商户订单ID",
    ph_status: "请选择状态",
    ph_chain_name: "请选择链名称",
    ph_asset_name: "请选择货币",
    ph_batch_id: "请输入批次编号（可多选）",
    ph_merchant_order_id: "可用逗号作分隔，输入多个商户订单ID",
    from_address: "发送地址",
    total_amount: "总金额",
    total_count: "总数量",
    created_by: "创建人",
    last_modified_date: "最后更新时间",
    view_operation: "查看操作",
    view_operation_transaction: "查看操作交易",
    resend: "重新发送",
    operation: "操作",
    resendSuccess: "重新发送成功",
    miner_fee_currency: "矿工费货币",
    miner_fee: "矿工费",
    actual_amount: "实际金额",
  },
  assetManagement: {
    invoker_wallet_address: "矿工费支付地址",
    miner_fee_asset_name: "矿工费货币",
    estimated_miner_fee: "预计矿工费",
    prepared_miner_fee: "已准备矿工费",
    last_estimated_miner_fee_date: "最后预计时间",
    re_estimate: "重新预计",
    estimating: "预计中",
    risk_level: "风险等级",
    acceptant_risk_level: "接受风险等级",
    ph_batch_sweep_risk_level: "请选择风险等级",
    ph_batch_sweep_acceptant_risk_level: "请选择接受风险等级 (可多选)",
    ph_batch_sweep_acceptant_risk_level_warning: "请选择接受风险等级",
    view: "查看",
    chain_name_and_currency: "链名称 / 货币",
    max_address_no: "最多可选地址",
    address_selected: "已选地址",
    max_sweepable_amount: "最多可扫金额",
    amount_to_sweep: "扫出金额",
    select_all: "全选",
    specific_sweep_amount: "指定扫出金额",
    ph_specific_sweep_amount: "请输入指定金额",
    ph_to_sweep_amount: "请输入扫出金额",
    ph_max_sweepable_address: "请输入最大可扫地址数量",
    auto_cal: "自动计算",
    excessive_amt_warning: "指定扫出金额不得大于最多可扫金额",
    bigger_than_0_warning: "扫出金额必须大于 0",
    no_invoker_address_warning: "选择矿工费支付钱包地址进行扫币请求",
    no_address_warning: "至少选择一个地址进行扫币请求",
    total_sweep_amount: "总额",
    accumative: "累计金额",
    max_sweepable_address: "最大可扫地址数量",
    accumativeAmt: "accumativeAmt",
    ph_status: "请选择状态",
    phProposal_no: "请输入提案号（可多选）",
    proposal_no: "提案号",
    chain_type: "链类型",
    chain_id: "链编号",
    withdraw: "取款",
    error_reason: "错误原因",
    rescan: "重扫",
    rescan_transaction_succeeded: "重扫交易成功",
    step_seq: "步骤顺序",
    tx_seq: "交易顺序",
    tx_status: "交易状态",
    tx_hash: "交易Hash",
    no_approval_permission: "没有相关审批权限",
    ph_approval_completed: "请输入已审批帐户数量",
    last_executed_by: "最后执行人",
    last_executed_date: "最后执行时间",
    transactionApproved: "提案已审批",
    transactionRejected: "提案已拒绝",
    transactionExecuted: "提案已执行",
    execute: "执行",
    all: "全部",
    position1: "第一",
    position2: "第二",
    position3: "第三",
    position4: "第四",
    remark: "备注",
    total_operation: "总操作数量",
    phMerchantOrderIds: "可用逗號作分隔，輸入多個商户订单ID",
    approval_progress: "审批进度",
    operation_type: "操作类型",
    phOperation_type: "请选择操作类型（可多选）",
    creator_type: "创建类型",
    phCreator_type: "请选择创建类型（可多选）",
    seq: "审批顺序",
    approval_result: "操作结果",
    operated_date: "操作时间",
    approvalContent: "请确定审批或拒绝此交易",
    approve: "审批",
    approval: "审批",
    approval_completed: "已审批帐户数量",

    tag: "标记",
    approval_threshold_amount: "取款少于或等于多少自动审批",
    asset_names: "货币",
    operation: "操作",
    edit: "编辑",
    edit_record: "编辑记录",
    add_record: "添加记录",
    chain_asset: "货币",
    request_record: "请求记录",
    withdraw_approval_setting: "取款审批设置",
    create_time: "创建时间",
    existing_amount: "修改前金额",
    edited_amount: "请求修改金额",
    creation_time: "创建时间",
    created_by: "创建人",
    operated_by: "操作人",
    operation_time: "操作时间",
    phApproval_status: "请选择状态",
    pending: "待审批",
    approved: "已审批",
    rejected: "已拒绝",
    withDrawApprovalSettingDialog: "审批设置",
    rejectQuestion: "确定拒绝此审批?",
    approval_status: "审批状态",
    ConfirmApprove: "审批成功",
    approveFail: "审批失败",
    ConfirmReject: "已拒绝请求",
    Fail: "失败",
    Success: "成功",
    reject: "拒绝",
    same_user_error: "操作人和创建人不能是同一个人",
    available_balance: "可取款金额",
    enter_yubi_key: "请输入 YubiKey",
    confirm: "确定",
    cancel: "取消",
    cannot_empty: "钱包地址或取款金额不能为空",
    sweepableAmount: "可扫金额",
    amountDidntChange: "金额没有变动，请修改金额",
    recordExist: "已存在相关记录",
    edit_request_created: "已生成修改请求",
    minimum_reserve_requirement: "当前最低预留額要求为 ",
    preview_warning:
      "%{asset} 的当前最低预留額要求为 %{amount}，所以扫币最少金额必须大于 %{amount}",
    approveOrReject: "审批/拒绝",
    clickToApprove: "审批",
    withdraw_succeed: "取款成功",
    total_count: "总数量",
    download_template: "下載範本",
    import_excel: "導入",
    total_amount: "总金额",
    submit: "提交",
    wallet_address: "钱包地址",
    withdraw_amount: "取款金额",
    add_item: "添加项目",
    phFrom_address: "发送地址",
    table: "列表",
    batchSweep: "批量扫币",
    sweepRequestRecord: "扫币申请记录",
    searchFilter: "查询条件",
    assetNotSupported: "不支持此货币",
    chain_name: "链名称",
    asset_name: "货币",
    threshold: "扫币最少金额",
    preview: "预览",
    decimals: "小数位",
    totalCount: "总数量",
    totalAmount: "总金额",
    coinSweep: "扫币",
    cryptSweeped: "已成功扫币",
    walletVersion: "钱包版本",
    address: "地址",
    from_address: "发送地址",
    to_address: "接收地址",
    invoker_address: "矿工费支付钱包地址",
    batch_id: "批次编号",
    created_date: "创建时间",
    last_modified_date: "最后更新时间",
    amount: "总额",
    status: "状态",
    search: "查询",
    reset: "重置",
    path: "钱包路径",
    viewOperation: "查看操作",
    viewOperationTransaction: "查看操作交易",
    startDate: "开始时间",
    endDate: "结束时间",
    noWalletAddressError: "没有相关钱包地址",
    withdrawRequestRecord: "取款申请记录",
    wallet_version: "钱包版本",
    phChain_name: "请选择链名称",
    phAsset_name: "请选择货币",
    ph_wallet_type: "请选择钱包类型",
    wallet_type: "钱包类型",
    phStatus: "请选择状态",
    phBatch_id: "请输入批次编号(（可多选）",
    phAddress: "请选擇地址",
    phThreshold: "请输入扫币最少金额",
    export: "导出",
    merchant_order_id: "商户订单ID",
    phMerchant_id: "可用逗号作分隔，输入多个商户订单ID",
    phAutoApproval_limit: "請輸入%{time}小时内取款自动审批限额（非必填）",
    existing_hourly_limit_before_edit: "修改前%{time}小时内自动审批限额",
    request_hourly_limit: "请求修改%{time}小时内取款自动审批限额",
    max_hourly_approve_limit: "%{time}小时内取款自动审批限额",
    limit_inputBox_description: "如果没有限制，则无需输入",
    merchant_operation: "商户操作",
    api_withdraw_tab: "API取款",
    portal_withdraw_tab: "后台取款",
    sweep_tab: "扫币",
    showAllRecord: "全部",
    showToDoRecord: "待办事项",
    showCompleted: "已完成事项",
    noOfApprover: "审批者数量",
    amountIslargerThan: "当总金额大于",
    regulation: "审批规则",
    maximumOfApprovers: "审批者的最大数量为 4",
    onlyAllowDeleteTheLargesNo: "请先移除审批者数量最多的项目",
    alertEmptyInput: "请在审批规则中输入金额",
    alertValueMustGreaterThan: "请输入大于上一行的金额",
    alertValueCannotBeTheSameAsExisitngAmount:
      "审批规则中的金额必须大于'取款少于或等于多少自动审批'的金额",
    assetIsEmpty: "请选择货币",
    chainNameIsEmpty: "请选择链名称",
    hourlyLimistMustBeGreaterThanAmtInRegulation:
      "%{time}小时内取款自动审批限额“的值必须等于或大于审批规则中的金额",
    before_amt: "修改前",
    after_amt: "修改后",
    MaxLimit_Within_1_Hour: "%{time}小时内审批总限额",
    maxNoOfTrxForAddressWith1Hr: "接收地址%{time}小时内最大交易取款笔数",
    maxNoOfTrxAmtForAddressWith1Hr: "接收地址%{time}小时内最高取款交易总额",
    reqEditMaxLimitWithin1Hr: "请求修改%{time}小时内取款审批总限额",
    reqEditMaxNoTrxs: "请求修改%{time}小时内最大交易取款笔数",
    reqEditMaxNoTrxAmt: "请求修改%{time}小时内最高取款交易总额",
    phMx_hourly_limit: "请输入%{time}小时内取款审批总限额（非必填）",
    phMx_addr_hrly_count:
      "请输入接收地址%{time}小时内最大交易取款笔数（非必填）",
    phMx_addr_hrly_limit:
      "请输入接收地址%{time}小时内最高取款交易总额（非必填）",
    phHourly_auto_approval_limt: "",

    noOfTrxsBeforeCreateTimeWithinOneHr:
      "创建时间前 %{checkingHour} 小时至当前交易笔数",
    totalTrxsAmtWithinTwentyFourhr: "24小时内总交易金额",
    totalTrxsAmtWithOneHr: "创建时间前 %{checkingHour} 小时至当前交易总额",
    receivingAddress: "接收地址",
    //
    mx_hourly_limit: "%{time}小时内审批总限额",
    mx_addr_hrly_count: "接收地址 %{time}小时内最大交易笔数",
    mx_addr_hrly_limit: "接收地址 %{time}小时内最高交易总额",
    total: "总数",
    auto_approval_disable: "系统目前只允许人工审批",
    auto_approval_amount_threshold:
      "当刻%{chain_name}的「取款少于或等于多少自动审批」设置为%{configAmt} %{asset_name}，交易金额超过该值",
    auto_approval_hourly_limit_amount:
      "当刻%{chain_name}的「%{time}小时内自动审批限额」设置为%{configAmt} %{asset_name}，创建申请时%{time}小时内自动审批金额已达到%{checking_value} %{asset_name}",
    all_approval_hourly_limit_amount:
      "当刻%{chain_name}的「%{time}小时内审批总限额」设置为%{configAmt} %{asset_name}，创建申请时%{time}小时内审批总金额已达到%{checking_value} %{asset_name}",
    to_address_hourly_limit_amount:
      "当刻%{chain_name}的「接收地址%{time}小时内最高交易总额」设置为%{configAmt} %{asset_name}，创建申请时当刻接收地址(%{receivingAddress})交易总额為%{checking_value}%{asset_name}",
    to_address_hourly_limit_count:
      "当刻%{chain_name}的「接收地址%{time}小时内最大交易笔数」设置为%{configAmt}，创建申请时当刻接收地址(%{receivingAddress})交易笔数為第%{checking_value}笔",
    prepare_txn_retry: "交易准备失败",
    exceeded_estimated_miner_fee: "超出预计矿工费 %{configAmt}%",
    exceeded_resubmit_hour_limit: "重新提交超过%{configAmt}小时",
    manual_approval: "手动审批原因",
    approval_op: "審批操作",
    settlement: "结算",
    type: "类型",
    pleaseEnterSettleMentAmt: "请在审批规则中输入结算限额",
    pleaseEnterWithDrawAmt: "请在审批规则中输入取款限额",
    pleaseEnterWithdrawAmtAndSettlementAmt:
      "请在审批规则中输入取款限额和结算限额",
    withdrawAmtMustBeGreater: "请在审批规则中输入大于上一行的取款限额",
    settleAmtMustBeGreater: "请在审批规则中输入大于上一行的结算限额",
    sequence: "序号",
    import_address_error: "导入的地址与系统中的地址不匹配",
    minLessThanOne: "可扫地址数量必须大于1",
    maxLargerThanOneThousand: "最大可扫地址数量为1000",
    previousSettleLimitIsEmpty: "请在审批规则中输入上一行结算限额",
    previousWithdrawLimitIsEmpty: "请在审批规则中输入上一行取款限额",
    allowIntOnly: "最大可扫地址数量必須是整數",
    ph_max_sweepable_addressL: "请输入最大可扫地址数量",
    ph_settle: "非必填",
    alert_creator_approver_same: "操作人和创建人不能是同一个人",
    alert_approvers_same: "审批人不能相同",
    alert_no_approval_permission: "没有相关权限",
    rebroadcast: "重新广播",
    rebroadcast_transaction_succeeded: "重新广播交易成功",
    last_modified_time: "最后更新时间",
    include_no_risk_level_wallet_filter: "此地址",
    risk_level_count: "缺少风险等级数据的交易数量",
    miner_fee_currency: "矿工费货币",
    miner_fee: "矿工费",
    actual_amount: "实际金额",
    wallet_settlement_time: "钱包余额更新时间",
  },
  walletManagement: {
    wb_should_not_be_zero_as_zero_is_excluded:
      "钱包余额不应为零，因为零已被排除",
    wallet_balance_filter_has_been_disabled: "钱包余额查询已被停用",
    wb_both_fields_in_wallet_balance_are_required:
      "钱包余额中的两个字段均为必填项",
    exclude_zero: "不包括零",
    wallet_balance: "钱包余额",
    ph_wallet_balance: "请输入钱包余额",
    ph_control_groups: "调整控制组",
    unassign: "未分配",
    client: "客户",
    settlement: "结算",
    tx_hash: "交易Hash",
    assetNotSupported: "不支持此货币",
    table: "列表",
    searchFilter: "查询条件",
    hotWallet: "热钱包",
    clientWallet: "用户钱包",
    merchant_id: "商户编号",
    client_id: "用户ID",
    chain_id: "链编号",
    chain_type: "链类型",
    chain_name: "链名称",
    wallet_type: "钱包类型",
    is_default: "默认钱包",
    set_wallet_to_default: "已设置为默认钱包",
    submitBtn: "提交",
    accountNo: "账号",
    walletStatus: "钱包状态",
    status: "Status",
    enabled: "已启用",
    disabled: "已禁用",
    deleted: "已删除",
    unassigned: "未分配",
    confirm: "确定",
    cancel: "取消",
    export: "导出",
    search: "查询",
    reset: "重置",
    assignWalletSuccess: "已成功分配钱包地址",
    wallet_address_already_existed: "钱包地址已存在",
    walletName: "钱包名称",
    walletAddress: "钱包地址",
    walletBalanceAdjustment: "钱包余额调整",
    balance: "钱包余额",
    asset_name: "货币",
    created_date: "创建时间",
    last_modified_date: "最后更新时间",
    set_default: "设为默认钱包",
    currentWalletBalance: "现时钱包余额",
    adjustAmountTo: "调整金额",
    lastTransactionDate: "最后交易时间",
    wallet_version: "钱包版本",
    accountNoError: "请输入帐号",
    chainTypeError: "请选择链名称",
    chainTypeSeriesError: "请选择同系列的链名称",
    chainIdError: "请选择链编号",
    currencyError: "请选择货币",
    walletTypeError: "请选择钱包类型",
    walletAddressError: "请输入钱包地址",
    adjustmentAmountError: "请输入调整金额",
    walletNameError: "请输入钱包名称",
    displayNameError: "请输入钱包显示名称",
    merchantIdError: "请输入商户ID",
    remarksError: "請輸入备注",
    walletStatusError: "请输入钱包状态",
    invalidAddressError: "请输入有效钱包地址",
    checkBalError: "Please check your current Wallet Balance",
    adjustAmountError: "请输入调整金额",
    operation: "操作",
    enableOrDisable: "已启用/已禁用",
    approvalProposalNo: "提案号",
    wallet_name: "钱包名称",
    display_name: "钱包显示名称",
    wallet_address: "钱包地址",
    wallet_tag: "钱包标签",
    x_address: "x_address", // TODO: wait for requirement update
    createAddress: "分配钱包地址",
    created_by_email: "创建人",
    approved_user: "操作人",
    approved_date: "操作时间",
    approval_status: "审批状态",
    pending: "待审批",
    approve: "审批",
    reject: "拒绝",
    approved: "已审批",
    rejected: "已拒绝",
    creationTimeStart: "创建开始时间",
    creationTimeEnd: "创建结束时间",
    approvalTimeStart: "操作开始时间",
    approvalTimeEnd: "操作结束时间",
    creationTime: "创建时间",
    approvalTime: "操作时间",
    startTime: "开始时间",
    endTime: "结束时间",
    amount: "金额",
    addBalanceAdjustment: "新增余额调整",
    approvalDetails: "审批提案明细",
    approveOrReject: "审批/拒绝",
    approvePropsalConfirmation: "确定审批此提案？",
    rejectPropsalConfirmation: "确定拒绝此提案？",
    transactionApproved: "提案已审批",
    transactionRejected: "提案已拒绝",
    completed: "已完成",
    amountMin: "Minimum Amount",
    amountMax: "Maximum Amount",
    adjustBalanceSuccess: "已新增余额调整提案",
    futureWalletBalance: "调整后余额",
    proposal_no: "提案号",
    date_created: "创建时间",
    adjustment_amount: "调整金额",
    apiError: "调用接口失败",
    checkBalanceError: "没有相关钱包地址",
    remark: "备注",
    clientTagError: "请输入用户ID",
    clientIdError: "请输入用户ID",
    phApprovalProposalNo: "可用逗号作分隔，输入多个提案号",
    phWallet_name: "可用逗号作分隔，输入多个钱包名称",
    phWalletName: "可用逗号作分隔，输入多个钱包名称",
    phDisplayName: "可用逗号作分隔，输入多个钱包显示名称",
    phClientId: "可用逗号作分隔，输入多个用户ID",
    phChain_name: "请选择链名称",
    phAsset_name: "请选择货币（可多选）",
    phWallet_address: "可用逗号作分隔，输入多个钱包地址",
    phWalletAddress: "可用逗号作分隔，输入多个钱包地址",
    phApproval_status: "请选择审批状态（可多选）",
    phStartTime: "开始时间",
    phEndTime: "结束时间",
    phClient_id: "请输入帐号",
    phWalletStatus: "请选择钱包状态（可多选）",
    phWalletType: "请选择钱包类型（可多选）",
    phWalletSingleType: "请选择钱包类型",
    phWallet_type: "请选择钱包类型（可多选）",
    latest_checkpoint_id: "最近检查点 ID",
    latest_checked_blocked_no: "最近检查区块号",
    certified_with_on_chain_balance: "与链上余额认证",
    last_certified_checkpointId: "最後认证检查点 ID",
    is_certified_balance: "与链上余额认证",
    ph_certified_balance: "与链上余额认证",
    same_user_error: "操作人和创建人不能是同一个人",
    control_groups_type_client: "客户",
    control_groups_type_settlement: "结算",
    control_groups_type_unassigned: "未分配",

    // Edit Display Name
    edit_wallet_name: "编辑钱包显示名称",
    existing_display_name: "现有钱包显示名称",
    new_display_name: "新钱包显示名称",
    edit_display_name_placeholder: "请输入新钱包显示名称",
    empty_display_name_error: "请先输入新钱包显示名称",
    edited_display_name_message: "成功修改钱包显示名称",

    // wallet tabs
    wallet_management_tab: "钱包管理",
    request_record_tab: "请求记录",

    addAddressBook: "导入地址",
    observeWalletError: "观察地址",
    delete_record: "删除",
    confirm_delete_wallet_record: "确认删除钱包记录？",
    deleted_wallet_record: "删除钱包记录",

    yubikey_id: "YubiKey ID",
    ph_yubikey_id: "请输入 Yubikey ID",
    request_address: "请求地址",
    created_by: "创建人",
    creation_time: "创建时间",
    operated_by: "操作者",
    operation_time: "操作时间",
    requested_address: "请求地址",
    ph_requested_address: "请输入请求地址",
    approval: "审批",
    approval_progress: "审批进度",
    no_approver_permission: "无审批权限",
    no_rejecter_permission: "没有拒绝者权限",
    approve_success: "批准成功",
    reject_success: "拒绝成功",
    request_record_created: "已生成修改请求",
    request_record_failed: "未能生成修改请求",
    edit_wallet_record: "编辑钱包记录",
    supported_chains: "支援的链",
    proposal_type: "提案类型",
    phProposalType: "请选择提案类型",
    chainTypeDifferent: "请选择具有先前链类型的链",
    phAddressBookSearch: "搜索钱包显示名称或钱包地址",
    allWallet: "全部钱包",
    observe_address: "观察地址",

    //UpdateWalletStatus
    enable: "启用",
    disable: "禁用",
    success: "成功",
    confirmBox: "Confirm",
    enableQuestion: "Confirm to enable the wallet?",
    disableQuestion: "Confirm to disable the wallet?",

    emptySearchFieldNotice: "请输入钱包显示名称或钱包地址",
    searchResultSingleCount: "显示%{total}條中的%{display}條結果",
    searchResultMultipleCounts: "显示%{total}條中的%{display}條結果",
    noChangesMade: "请修改字段",
    alert_creator_approver_same: "操作人和创建人不能是同一个人",
    alert_approvers_same: "审批人不能相同",
    alert_no_approval_permission: "没有相关权限",
    approveAndRejectButton: "审批/拒绝",
    risk_level: "风险等级",
    ph_risk_level: "请选择风险等级（可多选）",
    wallet_settlement_time: "钱包余额更新时间",
    assignWalletTime: "分配钱包时间",
  },

  report: {
    risk_level: "风险等级",
    ph_risk_level: "请选择风险等级（可多选）",
    batch_id: "批次编号",
    phBatch_id: "请输入批次编号（可多选）",
    date_range_exceed: "日期范围不能超过%{months}个月",
    summary_report: "总余额报表",
    table: "列表",
    searchFilter: "查询条件",
    search: "查询",
    export: "导出",
    cancel: "取消",
    operation: "操作",
    merchant_id: "商户编号",
    chain_id: "链编号",
    wallet_name: "钱包名称",
    display_name: "钱包显示名称",
    created_date: "创建时间",
    wallet_type: "钱包类型",
    client_id: "用户ID",
    chain_name: "链名称",
    trans_type: "交易类型",
    direction: "方向",
    status: "状态",
    completed: "已完成",
    wallet_address: "钱包地址",
    from_address: "发送地址",
    to_address: "接收地址",
    asset_name: "货币",
    asset_names: "货币",
    amount: "交易金额",
    trans_fee: "矿工费",
    post_balance: "钱包地址交易后余额",
    wallet_settlement_date: "钱包余额更新时间",
    is_fee: "费用记录",
    isFeeRecord: "费用记录",
    last_modified_date: "最后更新时间",
    chain_type: "链类型",
    tx_hash: "交易Hash",
    view: "查看交易",
    opening_balance: "期初余额",
    in: "入",
    out: "出",
    allTransactions: "全部交易",
    totalAmount: "总金额",
    confirmedfail: "确认失败",
    unconfirmed: "未确认",
    confirmedsuccess: "成功确认",
    invalid: "无效",
    reset: "重置",
    clientDeposit: "用户存款",
    withdrawDeposit: "从商户热钱包存入",
    clientWalletTop: "用户钱包充值",
    clientWithdrawal: "Client Withdrawal",
    confirmed: "已完成",
    pending: "Pending",
    openingBalance: "期初余额",
    sweetToHotWallet: "扫币至热钱包",
    sweep: "扫币",
    inOps: "(入)",
    outOps: "(出)",
    accountNoError: "请输入帐号",
    chainNameError: "请输入链名称",
    currencyError: "请输入货币",
    transTypeError: "请选择交易类型",
    transStatusError: "请选择交易状态",
    walletAddressError: "请输入钱包地址",
    toAddressError: "请输入发送地址",
    fromAddressError: "请输入接收地址",
    txHashError: "请输入交易Hash",
    isFeeError: "请选择是否费用记录",
    closingBalance: "期末余额",
    creationTimeStart: "创建开始时间",
    in_client_wallet_topup: "（入）用户钱包充值",
    creationTimeEnd: "创建结束时间",
    modificationTimeStart: "操作开始时间",
    modificationTimeEnd: "操作结束时间",
    block_hash: "区块Hash",
    block_number: "区块号",
    trans_date: "区块交易时间",
    confirmation: "确认区块",
    generalInfo: "交易通用信息",
    payerPayeeInfo: "交易发送/接收地址信息",
    baseEnvInfo: "区块链信息",
    date: "日期",
    in_hot_wallet_deposit: "（入）商户热钱包存款",
    in_sweep_to_hot_wallet: "（入）扫币至热钱包",
    in_hot_wallet_transfer: "（入）商户热钱包划转",
    in_hot_wallet_topup: "（入）商户热钱包充值",
    in_provision_withdraw: "（入）用户取款备用",
    in_withdraw: "（入）取款",
    in_invoker_deposit: "（入）矿工费支付钱包存款",
    in_invoker_transfer: "（入）矿工费支付钱包划转",
    in_merchant_deposit: "（入）商户存款",
    in_sweep: "（入）扫币",
    in_merchant_transfer: "（入）商户划转",
    in_settlement: "（入）结算",
    in_external_transfer: "（入）外部交易",
    in_settlement_to_hot_wallet: "（入）结算到热钱包",
    in_settlement_to_invoker_wallet: "（入）结算到矿工费支付钱包",
    in_settlement_to_sweep_dest_wallet: "（入）结算到扫币钱包",
    "in_settlement-deposit": "（入）从结算钱包存入",
    in_balance_adjustment: "（入）钱包余额调整",
    in_caution: "（入）需注意金额",
    out_withdraw: "（出）取款",
    out_merchant_withdraw: "（出）商户取款",
    out_withdraw_deposit: "（出）从商户热钱包存入",
    out_client_wallet_topup: "（出）用户钱包充值",
    out_hot_wallet_transfer: "（出）商户热钱包划转",
    out_hot_wallet_topup: "（出）商户热钱包充值",
    out_provision_for_withdraw: "（出）用户取款备用",
    out_invoker_transfer: "（出）矿工费支付钱包划转",
    out_merchant_transfer: "（出）商户划转",
    out_client_deposit: "（出）用户存款",
    out_hot_wallet_deposit: "（出）商户热钱包存款",
    out_invoker_deposit: "（出）矿工费支付钱包存款",
    out_settlement: "（出）结算",
    out_external_transfer: "（出）外部交易",
    out_withdraw_fee: "（出）取款矿工费",
    out_topup_fee: "（出）充值矿工费",
    out_merchant_withdraw_fee: "（出）商户取款矿工费",
    out_balance_adjustment: "（出）钱包余额调整",
    out_caution: "（出）需注意金额",
    in_client_deposit: "（入）用户存款",
    in_withdraw_deposit: "（入）从商户热钱包存入",
    out_sweep_to_hot_wallet: "（出）扫币至热钱包",
    out_sweep: "（出）扫币",
    out_sweep_fee: "（出）扫币矿工费",
    startDate: "开始时间",
    endDate: "结束时间",
    clientReport: "用户报表",
    dailyReport: "日报表",
    customReport: "自订周期报表",
    ref_no: "参考编号",
    copied: "已复制！",
    phStatus: "状态",
    phChain_name: "请输入链名称",
    phAsset_name: "请选择货币（可多选）",
    phWallet_type: "请选择钱包类型（可多选）",
    phWallet_name: "可用逗号作分隔，输入多个钱包名称",
    phDisplayName: "可用逗号作分隔，输入多个钱包显示名称",
    phWallet_address: "请输入钱包地址",
    phClient_id: "可用逗号作分隔，输入多个帐号",
    phDirection: "请选择方向（可多选）",
    phTrans_type: "请选择交易类型（可多选）",
    phFrom_address: "请输入发送地址",
    phTo_address: "请输入接收地址",
    phIs_fee: "请选择是否费用记录（可多选）",
    phTx_hash: "请输入交易Hash",
    phRef_no: "请输入参考编号",
    // Translate All transactions page/more details etc
    wallet_tag: "钱包标签",
    to_wallet_tag: "接收地址钱包标签",
    wallet_type_post_balance: "钱包交易后余额",
    is_certified_balance: "与链上余额认证",
    ph_certified_balance: "与链上余额认证",
    check_point_id: "检查点ID",
    certifiedWithOnChainBalance: "与链上余额认证",
    merchant_order_id: "商户订单ID",
    phMerchant_id: "可用逗号作分隔，输入多个商户订单ID",
    checkpoint: "检查点",
    reminder: "请点击“查询”以搜索记录",
    noRow: "没有相关记录",
    phDisplay_name: "请输入钱包显示名称",
    rescan_transaction_succeeded: "重扫交易成功",
    rescan_risk_level_succeeded: "重扫风险等级成功",
  },
  tools: {
    totalAmount: "总金额",
    edit_success: "编辑成功",
    disable_success: "禁用成功",
    delete_success: "删除成功",
    reject_success: "拒绝成功",
    approve_success: "审批成功",
    yubi_key_id: "YubiKey ID",
    yubikey_statuses: "YubiKey 状态",
    ph_login_email: "请输入登录电邮",
    ph_yubikey_id: "请输入YubiKey ID",
    ph_yubikey_statuses: "请选择YubiKey 状态",
    delete: "删除",
    confirm_delete: "确定删除",
    enable: "启用",
    disable: "禁用",
    id_serial: "序号",
    login_email: "登录电邮",
    registered_date: "注册时间",
    date_created: "注册时间",
    last_requested: "最后请求人",
    last_requested_time: "最后请求时间",
    last_date_operated: "最后操作时间",
    last_operated_by: "最后操作人",
    operation: "操作",
    approve: "审批",
    approval: "审批",
    reject: "拒绝",
    status: "状态",
    edit: "编辑",
    is_certified_balance: "与链上余额认证",
    onchain_balance: "链上余额",
    block_time: "区块时间",
    check_time: "检查时间",
    checkpoint_id: "检查点ID",
    wallet_address: "钱包地址",
    block_no: "区块号",
    startDate: "开始时间",
    endDate: "结束时间",
    ph_check_point_id: "请输入检查点ID",
    ph_is_certified_balance: "请输入与链上余额认证",
    ph_wallet_address: "请输入钱包地址",
    ph_blockNo: "请输入区块号",
    phAsset_name: "请选择货币（可多选）",
    export: "导出",
    re_broadcast: "重新广播",
    confirm_re_broadcast: "确定重新广播?",
    confirm_failure: "确认交易失败?",
    batch_id: "批次编号",
    operation_type: "操作类型",
    asset_name: "货币",
    total_amount: "总金额",
    total_count: "总数量",
    no_of_tx: "交易广播失败数量",
    batch_status: "批次状态",
    created_by: "创建人",
    created_date: "创建时间",
    phBatch_id: "请输入批次编号（可多选）",
    viewOperation: "查看操作",
    viewOperationTransaction: "查看操作交易",
    search: "查询",
    reset: "重置",
    batch_id_is_empty: "批次编号是空白的",
    broadcast_bacth_id_success: "已广播批次编号 %{batch_id}",
    have_yubi_registered: "您的账户已经注册YubiKey了!",
    otp: "一次性密码(OTP)",
    ph_otp: "请输入一次性密码",
    register: "注册",
    register_success: "注册成功",
    chain_name: "链名称",
    block_number: "区块号",
    rescan: "重扫",
    rescan_success: "重扫成功!",
    rescan_fail: "重扫失败!",
    ph_block_number: "请输入区块号",
    phChain_name: "请输入链名称",
    trx_hash: "交易Hash",
    ph_trx_hash: "请输入交易Hash",
    tx_hashes: "交易Hash",
    trx_hash_rescan_tab: "交易Hash重扫",
    block_rescan_tab: "区块重扫",
    trxhashFieldIsEmpty: "请输入链名称及交易Hash",
    blockNumberFieldIsEmpty: "请输入链名称及区块号",
    confirmFail: "确认失败",
    re_broadcast_tab: "重新广播",
    history_tab: "历史",
    currency: "货币",
    operated_by: "操作人",
    operation_time: "操作时间",
    ph_status: "请选择状态",
    ph_BatchStatus: "请选择批次状态",
    phAction: "请选择行为",
    yubikey_alteration_tab: "更改YubiKey",
    yubikey_register_tab: "注册YubiKey",
    yubikeyID: "YubiKey ID",
    yubikey_status: "YubiKey 状态",
    old_otp: "旧YubiKey一次性密码(OTP)",
    new_otp: "新YubiKey一次性密码(OTP)",
    alter: "更改",
    phOldOtp: "请输入旧一次性密码",
    phNewOtp: "请输入新一次性密码",
    completed: "已完成",
    executing: "处理中",
    failed: "失败",
    partialcompleted: "部份完成",
    requested: "已申请",
    signed: "已签署",
    signfailed: "签署失败",
    submitted: "已提交",
    date: "操作时间",
    phOperation_type: "请选择操作",
    bound: "已绑定",
    pendingApproval: "审批中",
    register_submitted: "已提交请求",
    date_last_modified: "最后更新时间",
    enterOtpAndNewOtp: "请输入旧一次性密码和新一次性密码",
    systemBalance: "系统余额",
    differenceBalance: "差额",
    Withdraw: "取款",
    confirmFailure: "确认失败",
    action: "行为",
    Sweep: "扫币",
    BatchStatus: "批次状态",
    Last_Update_Time: "最后更新时间",
    address_name: "地址名称",
    address: "地址",
    ph_address_name: "请输入地址名称",
    ph_address: "请输入地址",
    addAddress: "添加地址",
    creation_time: "创建时间",
    proposal_type: "提案类型",
    phProposal_type: "请选择提案类型",
    approval_status: "审批状态",
    phApproval_status: "请选择审批状态",
    proposal_no: "提案编号",
    approval_progress: "审批进度",
  },
  userApprovalManagement: {
    first: "第一",
    second: "第二",
    approval_permission: "审批權限",
    approval_progress: "审批进度",
    approval_statuses: "审批状态",
    approval: "审批",
    approve: "审批",
    approveOrReject: "审批/拒绝",
    approved: "已审批",
    approve_success: "审批成功",
    confirm_delete: "确认删除?",
    confirm_to_disable_approval_permission: "确认禁用审批?",
    confirm_to_enable_approval_permission: "确认允许审批?",
    confirm_to_unbind: "确认解绑?",
    created_by: "创建人",
    created_date: "创建时间",
    date_created: "创建时间",
    creation_time: "创建时间",
    delete: "Delete",
    disable_success: "禁用成功",
    disable: "禁用",
    email: "电邮",
    enable_success: "启用成功",
    enable: "启用",
    endDate: "结束时间",
    id_serial: "序号",
    last_operated_by: "操作人",
    last_operation_time: "最后操作时间",
    last_requested: "最后请求人",
    last_requested_time: "最后请求时间",
    login_email: "登录电邮",
    operated_by: "操作人",
    operation_result: "操作结果",
    operation_time: "操作时间",
    operation: "操作",
    ph_login_email: "请输入登录电邮",
    ph_proposal_type: "请选择提案类型",
    ph_approval_permission: "请选择审批权限",
    ph_approval_statuses: "请选择审批状态",
    ph_yubi_id: "请输入YubiKey ID",
    ph_yubikey_status: "请选择YubiKey状态",
    proposal_no: "提案号",
    proposal_status: "提案状态",
    proposal_type: "提案类型",
    registered_date: "注册时间",
    reject_success: "已成功拒绝",
    reject: "拒绝",
    rejected: "已拒绝",
    request_record: "请求记录",
    requested_yubi_key_id: "请求YubiKey Id",
    reset: "重置",
    same_approver_error: "审批人不能相同",
    same_user_error: "操作人不能相同",
    search: "查询",
    approval_sequence: "审批顺序",
    startDate: "开始时间",
    status: "状态",
    unbind_success: "解绑成功",
    unbind: "解绑",
    user_approval: "用户审批管理",
    yn_approval: "审批状态",
    yubi_id: "YubiKey ID",
    yubikey_id: "YubiKey ID",
    yubi_key_status: "YubiKey状态",
    yubikey_status: "YubiKey状态",
    alert_creator_approver_same: "操作人和创建人不能是同一个人",
    alert_approvers_same: "审批人不能相同",
    alert_no_approval_permission: "没有相关权限",
  },
  operations: {
    phMerchantOrderIds: "可用逗號作分隔，輸入多個商户订单ID",
    approval_progress: "审批进度",

    same_user_error: "操作人和创建人不能是同一个人",
    created_by: "创建人",
    approved_by: "操作人",
    approved_date: "操作时间",
    enter_yubi_key: "请输入YubiKey",
    confirm: "确定",
    table: "列表",
    operation: "操作",
    searchFilter: "查询条件",
    chain_name: "链名称",
    tx_hash: "交易Hash",
    amount: "总额",
    asset_name: "货币",
    created_date: "创建时间",
    from_address: "发送地址",
    to_address: "接收地址",
    to_wallet_tag: "接收地址钱包标签",
    batch_id: "批次编号",
    operation_batch_status: "操作批次状态",
    step_seq: "步骤顺序",
    tx_seq: "交易顺序",
    tx_status: "交易状态",
    operation_type: "操作类型",
    operation_seq: "操作顺序",
    operationRecords: "操作记录",
    merchant_order_id: "商户订单ID",
    phMerchant_id: "可用逗号作分隔，输入多个商户订单ID",
    path: "钱包路径",
    invoker_address: "矿工费支付地址",
    wallet_version: "钱包版本",
    total_operation: "总操作数量",
    total_step: "总步骤",
    total_count: "总数量",
    current_step: "现时步骤",
    moreDetail: "更多",
    signed_tx: "已签署交易",
    batchIdError: "输入批次编号",
    operationStatusError: "请输入操作状态",
    operationTypeError: "请输入操作类型",
    clientData: "用户数据",
    request_time: "申请时间",
    transaction_batch_id: "交易批次编号",
    operationDetails: "操作明细",
    moreDetails: "更多",
    approval: "审批申请",
    view: "查看",
    download: "下载",
    sign: "签署",
    approve: "审批",
    reject: "拒绝",
    search: "查询",
    reset: "重置",
    status: "状态",
    last_modified_date: "最后更新时间",
    callback_status: "回调状态",
    totalAmount: "总金额",
    cancel: "取消",
    transactionApproved: "提案已审批",
    transactionRejected: "提案已拒绝",
    uploadSuccess: "上载成功",
    uploadFailed: "上载失败",
    fileUpload: "上载档案",
    uploadBtn: "点击并上载",
    uploadFileDialog: "提示：签署后，系统将自动执行广播",
    creationTimeStart: "创建开始时间",
    creationTimeEnd: "创建结束时间",
    startDate: "开始时间",
    endDate: "结束时间",
    remark: "备注",
    action: "操作",
    phBatch_id: "请输入批次编号(可多选）",
    phChain_name: "请选择链名称",
    phAsset_name: "请选择货币（可多选）",
    phOperation_type: "请选择操作类型（可多选）",
    phStatus: "请选择状态（可多选）",
    export: "导出",
    approveOrReject: "审批/拒绝",
    last_modified_time: "最后更新时间",
    creator_type: "创建类型",
    phCreator_type: "请选择创建类型（可多选）",
    error_reason: "错误原因",
    approveAndRejectButton: "审批/拒绝",
    miner_fee_currency: "矿工费货币",
    miner_fee: "矿工费",
    actual_amount: "实际金额",
  },
  message: {
    table: "列表",
    header: "讯息管理",
    searchFilter: "查询条件",
    receivedTimeStart: "接收开始时间",
    receivedTimeEnd: "接收结束时间",
    lastAttemptTimeStart: "最后重试开始时间",
    lastAttemptTimeEnd: "最后重试结束时间",
    dataTypes: "数据类型",
    callbackStatuses: "回调状态",
    client_id: "用户ID",
    wallet_name: "钱包名称",
    txHashes: "交易Hash",
    merchantOrderIds: "商户订单ID",
    attemptCountGte: "重试次数多于",
    search: "查询",
    reset: "重置",
    hasReset: "已被重置",
    id: "ID",
    batch_id: "批次编号",
    batch_ids: "批次编号",
    operation_id: "操作编号",
    merchant_id: "商户编号",
    chain_name: "链名称",
    tx_hash: "交易Hash",
    error_reason: "错误原因",
    merchant_order_id: "商户订单ID",
    status: "状态",
    callback_batch: "回调批次",
    callback_seq: "回调顺序",
    data_type: "数据类型",
    callback_base_url: "回调基础URL",
    callback_url: "回调URL",
    callback_status: "回调状态",
    attempt_count: "重试次数",
    date_received: "接收时间",
    date_last_callback_attempt: "最后重试时间",
    startDate: "开始时间",
    endDate: "结束时间",
    phDataTypes: "请选择数据类型（可多选）",
    phCallbackStatuses: "请选择回调状态（可多选）",
    phClient_id: "可用逗号作分隔，输入多个用户ID",
    phWallet_name: "可用逗号作分隔，输入多个钱包名称",
    phTxHashes: "可用逗號作分隔，輸入多個交易Hash",
    phMerchantOrderIds: "可用逗號作分隔，輸入多個商户订单ID",
    phAttemptCountGte: "请输入重试次数",
    phBatchId: "请输入批次编号（可多选）",
    resetConfirmation: "确认重置重试次数?",
    confirm: "确认",
    cancel: "取消",
    reminder: "请点击“查询”以搜索记录",
    noRow: "没有相关记录",
  },
  enumConstants: {
    eq: "等于",
    gt: "大于",
    gte: "大于等于",
    lt: "小于",
    lte: "小于等于",
    "": "",
    timezone0: "+0",
    timezone1: "+1",
    timezone2: "+2",
    timezone3: "+3",
    timezone4: "+4",
    timezone5: "+5",
    timezone6: "+6",
    timezone7: "+7",
    timezone8: "+8",
    timezone9: "+9",
    timezone10: "+10",
    timezone11: "+11",
    timezone12: "+12",
    timezone13: "-11",
    timezone14: "-10",
    timezone15: "-9",
    timezone16: "-8",
    timezone17: "-7",
    timezone18: "-6",
    timezone19: "-5",
    timezone20: "-4",
    timezone21: "-3",
    timezone22: "-2",
    timezone23: "-1",
    en: "English",
    zh_cn: "简中",
    Bound: "已绑定",
    Unbound: "未绑定",
    PendingToBeBound: "待绑定",
    PendingProposalApproval: "待审批",
    PendingForApproval: "审批中",
    YubiKeyRegistration: "注册 YubiKey",
    YubiKeyRegister: "注册 YubiKey",
    UnbindYubiKey: "解绑 YubiKey",
    UpdateYubiKey: "更改 YubiKey",
    RebindYubiKey: "重绑 YubiKey",
    EnableApprovalPermission: "启用审批權限",
    DisableApprovalPermission: "禁用审批權限",
    CreateRecord: "创建记录",
    Edit: "编辑",
    Approve: "审批",
    Delete: "删除",
    Rescan: "重扫",
    Login: "登录",
    Re_Broadcast: "重新广播",
    auto: "自动",
    optional: "非必填",
    login: "登录",
    "Internal Server Error": "内部服务器错误",
    "Login Token Error": "登录代币错误",
    "Invalid Login Token": "登录代币无效",
    "Database Error": "数据库错误",
    "Network Error": "网络错误",
    "Invalid Argument": "无效参数",
    "Invalid Login Token Type": "登录代币类型无效",
    SubmitFailed: "提交失败",
    SignFailed: "签署失败",
    Failed: "失败",
    Reject: "拒绝",
    Requested: "已申请",
    Submitted: "已提交",
    Executing: "处理中",
    Completed: "已完成",
    "Partial Completed": "部份完成",
    Signed: "已签署",
    Deleted: "已删除",
    Disabled: "已禁用",
    Unassigned: "未分配",
    Enabled: "已启用",
    ClientWallet: "用户钱包",
    "External Wallet": "外部钱包",
    "Client Wallet": "用户钱包",
    "Hot Wallet": "热钱包",
    "Invoker Wallet": "矿工费支付钱包",
    "Sweep Dest Wallet": "扫币钱包",
    "Address Book": "地址簿钱包",
    "Settlement Wallet": "结算钱包",
    ExternalWallet: "外部钱包",
    HotWallet: "热钱包",
    InvokerWallet: "矿工费支付钱包",
    SweepDestWallet: "扫币钱包",
    AddressBook: "地址簿钱包",
    SettlementWallet: "结算钱包",
    "No Related Wallet Address": "无相关钱包地址",
    no_related_wallet_address: "无相关钱包地址",
    no_related_wallet_type_address: "无相关%{walletType}地址",
    "No Related External Wallet Address": "无相关外來钱包地址",
    "No Related Client Wallet Address": "无相关用户钱包地址",
    "No Related Hot Wallet Address": "无相关热钱包地址",
    "No Related Invoker Wallet Address": "无相关矿工费支付地址",
    "No Related Sweep Dest Wallet Address": "无相关扫币钱包地址",
    "No Related Settlement Wallet Address": "无相关结算钱包地址",
    "External Miner Fee": "外部矿工费",
    In: "入",
    Out: "出",
    IN: "入",
    OUT: "出",
    Yes: "是",
    yes: "是",
    No: "否",
    no: "否",
    NO_DIRECTION: "没有",
    Adjustment: "余额调整",
    ConfirmedFail: "确认失败",
    Unconfirmed: "未确认",
    ConfirmedSuccess: "确认成功",
    Invalid: "无效",
    "External Transfer": "外部交易",
    "Client Deposit": "用户存款",
    "Hot Wallet Deposit": "商户热钱包存款",
    "Invoker Deposit": "矿工费支付钱包存款",
    LOSS10: "LOSS10",
    CAUTION11: "CAUTION11",
    "Sweep to Hot Wallet": "扫币至热钱包",
    CAUTION13: "CAUTION13",
    Sweep: "扫币",
    CAUTION15: "CAUTION15",
    CAUTION16: "CAUTION16",
    MinerFee: "矿工费用",
    Withdraw: "取款",
    "Withdraw Deposit": "从商户热钱包存入",
    "Hot Wallet Transfer": "商户热钱包划转",
    CAUTION23: "CAUTION23",
    CAUTION24: "CAUTION24",
    "Withdraw Account Activation": "取款地址激活",
    Settlement: "结算",
    "Withdraw Fee": "取款矿工费",
    LOSS30: "LOSS30",
    "Client Wallet Topup": "用户钱包充值",
    "Hot Wallet Topup": "商户热钱包充值",
    "Invoker Transfer": "矿工费支付钱包划转",
    CAUTION34: "CAUTION34",
    CAUTION35: "CAUTION35",
    "Sweep Account Activation": "掃幣錢包激活",
    "Topup Fee": "充值矿工费",
    PrepareRetry: "PrepareRetry",
    Rejected: "已拒绝",
    Approved: "已审批",
    Pending: "待审批",
    PendingForEnabled: "待审批",
    ReceivedAll: "全部已接收",
    Prepared: "已准备",
    Retry: "重试中",
    Incompleted: "未完成",
    VerifyFailed: "验证失败",
    Broadcasted: "已广播",
    Verified: "已验证",
    true: "正确",
    false: "错误",
    FastConfirmedSuccess: "初步确认",
    BroadcastFailed: "广播失败",
    FastConfirmedFail: "初步确认失败",
    Deposit: "存款回调",
    Desposit: "存款",
    HotWalletDepositStatus: "热钱包存款状态回调",
    Operation: "操作回调",
    Operationbatch: "操作批次回调",
    Error: "错误",
    PushErr: "推送错误",
    undefined: "",
    CreatorPortal: "后台操作",
    CreatorApi: "API",
    // asset_list
    batch_sweep: "批量扫币",
    sweep_request_record: "扫币申请记录",
    batch_withdraw: "批量取款",
    withdraw_request_record: "取款申请记录",
    operation_records: "操作记录",
    operation_transaction_records: "操作交易记录",
    merchant_operation: "商户操作",
    withdraw_approval_setting: "取款审批设置",
    merchant_wallet_management: "商户钱包管理",
    client_wallet_management: "用户钱包管理",
    total_balance_overview: "总余额概览",
    wallet_balance_adjustment: "钱包余额调整",
    message_management: "讯息管理",
    block_rescan: "重扫",
    re_broadcast: "重新广播",
    yubikey_register: "YubiKey 注册",
    on_Chain_balance_checkpoint: "余额检查点",
    confirmFailure: "确认失败",
    //OpType in AuditLog://endpointList
    Create_record: "创建记录",
    Re_broadcast: "重新广播",
    YubiKey_register: "YubiKey 注册",
    Switch_to_manual_approval_process: "切换手动审批流程",
    ledger_deposit_record: "Ledger存款记录",
    ledger_withdraw_request: "Ledger取款申请",
    ledger_transfer_request: "Ledger转账申请",
    ledger_client_management: "Ledger用户管理",
    LedgerDeposit: "Ledger存款",
    LedgerWithdraw: "Ledger取款",
    LedgerTransfer: "Ledger转账",
    LedgerTransaction: "Ledger交易",
    depositStatus: "存款回调",
    operationStatus: "操作回调",
    operationBatchStatus: "操作批次回调",
    blockNumber: "区块号回调",
    hotwalletDepositStatus: "热钱包存款状态回调",
    ledgerWithdrawRequest: "Ledger取款申请回调",
    ledgerTransferRequest: "Ledger转账申请回调",
    ledgerTransaction: "Ledger交易回调",
    ledgerDeposit: "Ledger存款回调",
    PartialCompleted: "部份完成",
    Stucking: "待决定",
    user_approval_management: "用户審批管理",
    asset_list: "货币列表",
    account_setting: "账户设置",
    address_generation: "地址生成",
    wallet_management: "钱包管理",

    //
    // address_book --> request_record_tab
    AddAddress: "添加地址",
    DeleteAddress: "删除地址",

    "Add Record": "添加记录",
    "Edit Record": "编辑记录",
    "Delete Record": "删除记录",
    Unused: "未使用",
    Used: "已使用",
    system: "SYSTEM",
    house: "HOUSE",
    client: "CLIENT",
    System: "SYSTEM",
    House: "HOUSE",
    Client: "CLIENT",
    Transfer: " 转账",
    WithdrawFee: "取款手续费",
    TransferFee: "转账手续费",
    WithdrawRefund: "退款",
    WithdrawFeeRefund: "取款手续费退款",
    FreezeBalance: "冻结余额",
    UnFreezeBalance: "解冻余额",
    risk_acceptant: "接受风险等级",
    NoData: "-2 没有数据",
    NoAssessment: "-1 没有评估",
    NoRiskDetected: "0 未检测到风险",
    Info: "1 信息",
    Caution: "2 需注意",
    Medium: "3 中",
    High: "4 高",
    Critical: "5 极高",
    ConfirmFail: "确认失败",
    Portal: "后台操作",
    Api: "API",
    initial: "初始",
    Execute: "执行中",
    ExecuteSuccess: "成功执行",
    Refund: "退款",
    RefundSuccess: "成功退款",
    AwaitRiskLevel: "风险等级评估中",
    Confirmed: "确认成功",
    ConfirmFailed: "确认失败",
    FastConfirmed: "初步确认成功",
    includeNoRiskWallet: "仅包括缺少风险等级数据的交易",
    notIncludeNoRiskWallet: "不包括缺少风险等级数据的交易",
    Frozen: "冻结",
    Unfrozen: "解冻",
    timeout: "超时",
    httpErrorCode: '请求失败，状态代码'
  },
  enumWithDrawApprovalSetting: {
    pending: "待审批",
    rejected: "已拒绝",
    approved: "已审批",
  },
  callbackSetting: {
    block_number: "区块号回调",
    deposit_status: "存款回调",
    Hot_Wallet_DepoSit_Status: "热钱包存款状态回调",
    operation_status: "操作回调",
    operation_batch_status: "操作批次回调",
    depositStatus: "存款回调",
    operationStatus: "操作回调",
    operationBatchStatus: "操作批次回调",
    blockNumber: "区块号回调",
    hotwalletDepositStatus: "热钱包存款状态回调",
    ledgerWithdrawRequest: "Ledger取款申请回调",
    ledgerTransferRequest: "Ledger转账申请回调",
    ledgerTransaction: "Ledger交易回调",
    ledgerDeposit: "Ledger存款回调",
    table: "列表",
    merchant_id: "商户编号",
    data_type: "数据类型",
    send: "发送",
    last_modified_by_id: "最后修改人",
    date_last_modified: "最后修改日期",
    activate: "启用",
    disable: "禁用",
    Confirm: "確定",
    Return: "返回",
    Edit: "编辑",
    Error: "錯誤",
    confirm: "更新成功",
    cancel: "取消",
    callbackSettingToolTip: "回调设定",
  },
  merchantOperationModel: {
    paymentWallet: "Payment Wallet",
    approve: "审批",
    sign: "签署",
    callback: "回调",
    sweep: "扫币",
    withdraw: "取款",
    automate: "自动",
    yes: "是",
    no: "否",
    manual: "人工",
    return: "返回",
  },
  withdrawSetting: {
    merchantName: "商户名称",
    merchantWithdrawStatus: "商户取款状态",
    enable: "启用",
    disable: "停用",
    switchWithdrawStatus: "切换商户取款状态",
    switchWithdrawStatusReminder: "商户取款状态切换到",
    updateSettingSuccess: "更新设置成功",
  },
  totalWalletBalance: {
    id: "ID",
    table: "列表",
    wallet_type: "钱包类型",
    chain_name: "链名称",
    asset_name: "货币",
    phAsset_name: "请选择货币",
    balance: "总余额",
    last_modified_date: "最后更新时间",
    merchant_id: "商户编号",
    phWallet_type: "请选择钱包类型（可多选）",
    phChain_name: "请选择链名称",
    search: "查询",
    reset: "重置",
    export: "导出",
  },
  auditLog: {
    table: "列表",
    request_time: "请求时间",
    function_name: "功能名称",
    operation: "操作",
    batch_id: "批次编号",
    response_time: "响应时间",
    operator: "操作人",
    serial_no: "序号",
    startDate: "开始时间",
    endDate: "结束时间",
    phFunction_name: "请请选择功能名称（可多选）",
    phOperation: "请请选择操作（可多选）",
    phBatch_id: "请输入批次编号(可多选）",
    phOperator: "请输入操作人（可多选）",
    phSerial_no: "请输入序号（可多选）",
    api_url: "API URL",
    viewDetailsOperation: "操作",
    search: "查询",
    reset: "重置",
    portal_operation_tab: "后台操作",
    api_in_operation_tab: "API调用（入）",
    api_out_operation_tab: "API调用（出）",
    cancel: "取消",
    details: "详情",
    requesHeader: "请求标头",
    requestBody: "请求体",
    requestResp: "响应讯息",
    resposneTime: "响应时间",
    relatedLog: "相关日志",
    res_date: "请求时间",
    //
    view: "查看详情",
    //
  },

  assetList: {
    table: "列表",
    asset_name: "货币",
    chain_name: "链名称",
    decimals: "小数位",
    withdraw_status: "取款状态",
    last_operated_by: "最后操作人",
    last_operation_time: "最后操作时间",
    phChain_name: "请选择链名称",
    phWithdraw_status: "请选择取款状态",
    phAsset_name: "请选择货币（可多选）",
    search: "查询",
    reset: "重置",
    operation: "操作",
    Enable: "已启用",
    Disable: "已禁用",
    withdrawStatus: "取款状态",
    statusHantChange: "请修改取款状态",
    confirm: "确定",
    confirmed: "已确定",
    cancel: "取消",
    edit: "编辑",
    //temp use
    view: "",
    approve: "",
    download: "",
    sign: "",
    push_deposit_message: "推送存款消息",
    ph_push_deposit_message: "请选择推送存款消息",
  },
  AddressGeneration: {
    chain_name: "链名称",
    wallet_types: "钱包类型",
    wallet_version: "钱包版本",
    sum_addr_generated: "总生成地址数量",
    addr_used_count: "已使用地址数量",
    addr_unused_count: "未使用地址数量",
    phChain_name: "请选择链名称",
    phWallet_type: "请选择钱包类型",
    address_generation: "地址生成",
    phNumOfAddress: "请选择地址生成数量",
    import: "上载档案",
    cancel: "取消",
    confirm: "确定",
    uploadAddress: "上载",
    Import: "上载",
    success_generate_txt: "文件已下载",
    success: "成功",
    click_here_import: "点击并上载",
    import_success: "上载成功",
    import_failed: "上载失败",
  },
  NonceTool: {
    nonceTab: "Nonce",
    priorityListTab: "优先列表",
    status: "状态",
    ph_status: "请选择状态（可多选）",
    sending_address: "发送地址",
    ph_sending_address: "请输入发送地址",
    batch_id: "批次编号",
    ph_batch_id: "请输入批次编号",
    chain_name: "链名称",
    ph_chain_name: "请选择链名称",
    step_sequence: "步骤顺序",
    transaction_sequence: "交易顺序",
    nonce: "Nonce",
    operation_type: "操作类型",
    transaction_hash: "交易Hash",
    creation_time: "创建时间",
    last_modified_time: "最后更新时间",
    insert: "插入",
    back: "返回",
    next: "下一步",
    confirm: "确定",
    chain_name_confirm: "链名称",
    sending_address_confirm: "发送地址",
    next_nonce: "下一个Nonce",
    last_chain_nonce: "最新的Nonce",
    input_range: "输入范围",
    ph_nonce: "请输入Nonce",
    allFieldsShouldBeFilled: "请填写所有字段",
    deleted: "已删除",
    delete: "删除",
    operation: "操作",
    delete_dialog: "确认删除Nonce插入记录？",
    insert_details: "插入记录细节",
    deleted_success_alert: "已刪除Nonce插入记录",
    deleted_fail_alert: "删除Nonce插入记录失败",
    inserted_success_alert: "Nonce插入成功",
    inserted_fail_alert: "Nonce插入失败",
    insert_nonce_error: "请输入在输入范围内的Nonce",
    next_nonce_notice: "(下一个在系统中使用的Nonce)",
    last_nonce_notice: "(链上的最新Nonce)",
    select_chain_name_first: "请选择链名称",
    id: "ID",
    reminder: "请输入链名称并点击“查询”搜索记录",
    noRow: "没有相关记录",
    no_input_range: "没有输入范围可以插入",
  },
  utxoManagement: {
    syncSuccessful: "相关钱包地址的UTXO已与链同步",
    updatedFailed: "更新UTXO失败",
    utxoModal: "UTXO同步",
    btc_utxo_tab: "BTC系列",
    ada_utxo_tab: "ADA",
    create_time: "创建时间",
    last_modified_time: "最后更新时间",
    wallet_address: "钱包地址",
    tx_hash: "交易Hash",
    asso_tx_hash: "关联交易Hash",
    ph_tx_hash: "请输入交易Hash",
    ph_asso_tx_hash: "请输入关联交易Hash",
    ph_wallet_address: "请输入钱包地址",
    phChain_name: "请选择链名称",
    chain_name: "链名称",
    currency: "货币",
    delete: "删除",
    vout: "输出指数 (Vout)",
    spent_tx_hash: " 使用于 (交易Hash)",
    status: "状态",
    creation_time: "创建时间",
    helperDelText: "如要删除相关UTXO，请先查询相关的交易Hash",
    utxo_id: "UTXO ID",
    phStartTime: "开始时间",
    phEndTime: "结束时间",
    alertIfNotHotWallet: "输入的钱包地址的钱包类型必须为热钱包",
    copied: "已复制！",
    reminder: "请选择链名称及输入钱包地址搜寻记录",
    noRow: "没有相关记录",
    successDeleteAlert: "已成功删除相关UTXO及其后续关系",
    failed_to_delete_utxo: "删除UTXO失败",
    mustInputChainName: "请选择链名称",
    mustInputWalletAddress: "请输入钱包地址",
    unused: "未使用",
    used: "已使用",
    invalid: "无效",
    confirmDelete: "确认删除?",
    sync_utxo: "Sync UTXO",
    Delete: "删除",
    undefined: "undefined",
    Adareminder: "请输入钱包地址搜寻记录",
    inputChainNameAddress: "请选择链名称及输入钱包地址搜寻记录",

    syncAlert: "请选择链名称及输入钱包地址",
  },
  resubmit: {
    tableTab: "列表",
    columns: {
      operation: "操作",
      batch_id: "批次编号",
      operation_type: "操作类型",
      chain_name: "链名称",
      asset_name: "货币",
      total_amount: "总金额",
      total_count: "总操作数量",
      status: "批次状态",
      created_by: "创建人",
      created_date: "创建时间",
    },
    filter: {
      created_date: "创建时间",
      operation_type: "操作类型",
      chain_name: "链名称",
      asset_name: "货币",
      batch_id: "批次编号",

      phChain_name: "请选择链名称",
      phOperation_type: "请选择操作类型",
      phAsset_name: "请选择货币",
      phBatch_id: "请输入批次编号",
      reset: "重置",
    },
    prompter: {
      title: "确定重新提交?",
    },
    alert: {
      success: "重新提交成功",
    },
    resubmitBtn: "重新提交",
  },

  depositAndWithdrawalStatistics: {
    block_time: "区块时间",
    block_time_from: "区块开始时间",
    block_time_to: "区块结束时间",
    chain_name: "链名称",
    currency: "货币",
    ph_chain_name: "请选择链名称",
    ph_currency: "请选择货币 (可多选）",
    deposit_number_of_transactions: "存款（交易笔数）",
    deposit_total_transaction_amount: "存款（交易总额）",
    withdraw_number_of_transactions: "取款（交易笔数）",
    withdraw_total_transaction_amount: "取款（交易总额）",
    block_time_warning: "请选择区块时间",
  },

  errorCode: errorCode.zh_cn,
};

export default json;
