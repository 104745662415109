import lang from "ra-language-english";

import errorCode from "../../utils/errorCode.json";

const json = {
  ra: lang.ra,
  common: {
    "": "",
    enter_yubi_key: "Enter YubiKey",
    approval: "Approval",
    approve: "Approve",
    cancel: "Cancel",
    confirm: "Confirm",
    download: "Download",
    edit: "Edit",
    reject: "Reject",
    reset: "Reset",
    search: "Search",
    sign: "Sign",
    status: "Status",
    submit: "Submit",
    save: "Save",
    view: "View",
    export: "Export",
    table: "Table",
    start_date: "Start Date",
    end_date: "End Date",
    date_hr_start_date: "Start Date",
    date_hr_end_date: "End Date",
    no_data_export: "No data for export",
    copied: "Copied!",
    yes: "Yes",
    no: "No",
    true: "True",
    false: "False",
    alert_creator_approver_same:
      "The operator and creator must not be the same",
    alert_approvers_same: "The approvers must not be the same",
    alert_no_permission: "No relevant permission",
    input_hours_only_warning: "Please input only hours",
    please_click_search: "Please click SEARCH for searching records",
    date: "Date",
    time: "Time",
    column_hidden_message: "Some columns are hidden",
    columnMenuShowColumns: "Show columns",
    columnMenuHideColumn: "Hide",
    columnsPanelTextFieldLabel: "Find column",
    columnsPanelTextFieldPlaceholder: "Column title",
    columnsPanelShowAllButton: "Show all",
    columnsPanelHideAllButton: "Hide all",
  },
  general: {
    autoLogoutAlert:
      "Automatically logged out because another account is already logged in from same browser",
    logoutAlert: "You have been logged out",
  },
  yubiKeyHook: {
    please_enter: "Please Enter",
    ph_yubikey: "YubiKey OTP",
    ph_remarks: "Remarks (Optional)",
  },
  accountSetting: {
    lang: "Language",
    email: "Login Email",
    yubikey_status: "YubiKey Status",
    timezone: "Time Zone",
    language: "Language",
  },
  config: {
    noTokenError: "No Token",
    invalidTokenError: "Invalid token",
    redirecting: "Redirecting",
    login: "Login",
    assetNotSupported: "Asset is not supported",
  },
  component: {
    typeToSearch: "Type to Search",
    noRecords: "No relevant record exists",
    itemTotalStart: "",
    itemTotalEnd: " item(s) in total",
    merchantWithdrawStatus: "Merchant Withdraw Status",
    disable: "Disabled",
  },
  menu: {
    homepage: "",
    account_setting: "Account Setting",
    walletManager: "Wallet Manager",
    batch_sweep: "Batch Sweep",
    sweep_request_record: "Sweep Request Record",
    batch_withdraw: "Batch Withdraw",
    withdraw_request_record: "Withdraw Request Record",
    client_wallet_management: "Client Wallet Management",
    wallet_balance_adjustment: "Wallet Balance Adjustment",
    merchant_wallet_management: "Merchant Wallet Management",
    wallet_management: "Wallet Management",
    wallet_balance: "Wallet Balance",
    merchant_report: "Merchant Report",
    client_report: "Client Report",
    logout: "Log Out",
    dashboard: "Dashboard",
    operation_records: "Operation Records",
    operation_transaction_records: "Operation Transaction Records",
    message_management: "Message Management",
    deposit_and_withdrawal_statistics: "Deposit and Withdrawal Statistics",
    transaction_report: "All Transactions",
    merchant_operation: "Merchant Operation",
    withdraw_setting: "Withdraw Setting",
    withdraw_approval_setting: "Withdraw Approval Setting",
    withdraw_approval_checking_time_setting:
      "Withdraw Approval Checking Time Setting",
    assetManagement: "Asset Management",
    walletManagement: "Wallet Management",
    report: "Report",
    tools: "Tools",
    callback_setting: "Callback Setting",
    welcome: "Welcome",
    total_wallet_balance: "Total Balance Overview",
    block_rescan: "Rescan",
    re_broadcast: "Rebroadcast",
    yubi_key_register: "YubiKey Register",
    yubi_key_management: "YubiKey Management",
    user_approval_management: "User Approval Management",
    balance_checkpoint: "On-Chain Balance Checkpoint",
    nonce_tool: "Nonce Tool",
    audit_log: "Audit Log",
    asset_list: "Asset List",
    address_generation: "Address Generation(Offline)",
    version: "Version",
    address_book: "Address Book",
    utxo_tool_management: "UTXO Tool",
    ledger: "Ledger",
    wallet_manager_deposit_record: "Deposit Record",
    withdraw_request: "Withdraw Request",
    transfer_request: "Transfer Request",
    client_management: "Client Management",
    client_balance: "Client Balance",
    ledger_transaction_record: "Ledger Transaction Record",
    ledger_freeze_request: "Ledger Freeze Request",
    ledger_frozen_fund: "Ledger Frozen Fund",
    re_submit: "Resubmit",
  },
  ledgerClientBalance: {
    creation_time: "Creation Time",
    last_modified_date: "Last Modified Time",
    chain_name: "Chain Name",
    currency: "Currency",
    client_id: "Client ID",
    ph_chain_name: "Please select Chain Name",
    ph_currency: "Please select Currency",
    ph_client_id: "Please enter Client ID",
    client_balance: "Client Balance",
    exclude_zero: "Exclude Zero",
    cb_both_fields_in_client_balance_are_required:
      "Both fields in Client Balance are required",
    cb_should_not_be_zero_as_zero_is_excluded:
      "Client Balance should not be zero as zero is excluded",
    ph_client_balance: "Please enter Client Balance",
    frozen_balance: "Frozen Balance",
    credit: "Credit",
  },
  ledgerClientManagement: {
    creation_time: "Creation Time",
    last_modified_date: "Last Modified Time",
    chain_name: "Chain Name",
    client_type: "Client Type",
    withdrawable: "Withdrawable",
    transferable: "Transferable",
    allow_credit_setting: "Allow Credit Setting",
    disabled_client: "Disabled Client",
    ph_withdrawable: "Withdrawable Client",
    ph_transferable: "Transferable Client",
    ph_allow_credit_setting: "Allow Credit Setting",
    ph_disabled_client: "Disabled Client",
    operation: "Operation",
    ph_client_type: "Please select Client Type",
    currency: "Currency",
    client_id: "Client ID",
    system: "SYSTEM",
    house: "HOUSE",
    client: "CLIENT",
    ph_chain_name: "Please select Chain Name",
    ph_currency: "Please select Currency",
    ph_client_id: "Please enter Client ID",
    balance: "Balance",
    frozen_balance: "Frozen Balance",
    credit: "Credit",
    yes: "Yes",
    no: "No",
    edit: "Edit",
    editClient: "Edit Client",
    addClient: "Add Client",
    addNewClient: "Add New Client",
    editError: "There is no value change. Please edit.",
    addErrorId: "Please enter Client ID",
    addErrorType: "Please select Client Type",
    successAdd: "Created New Client Successfully",
    successEdit: "Edited Successfully",
  },

  ledgerTransactionRecord: {
    creation_time: "Creation Time",
    direction: "Direction",
    transaction_type: "Transaction Type",
    client_id: "Client ID",
    sending_client_id: "Sending Client ID",
    receiving_client_id: "Receiving Client ID",
    chain_name: "Chain Name",
    currency: "Currency",
    original_order_id: "Original Order ID",
    merchant_order_id: "Merchant Order ID",
    ledger_transaction_id: "Ledger Transaction ID",
    ph_direction: "Please select Direction",
    ph_transaction_type: "Please select Transaction Type",
    ph_client_id: "Please enter Client ID",
    ph_sending_client_id: "Please enter Sending Client ID",
    ph_receiving_client_id: "Please enter Receiving Client ID",
    ph_chain_name: "Please select Chain Name",
    ph_currency: "Please select Currency",
    ph_original_order_id: "Please enter Original Order ID",
    ph_merchant_order_id: "Please enter Merchant Order ID",
    ph_ledger_transaction_id: "Please enter Ledger Transaction ID",
    sending_wallet_address: "Sending Wallet Address",
    receiving_wallet_address: "Receiving Wallet Address",
    transaction_amount: "Transaction Amount",
    post_balance: "Post Balance",
    freeze_amount: "Freeze Amount",
    post_frozen_balance: "Post Frozen Balance",
    remarks: "Remarks",
    operation: "Operation",
    resend: "Resend",
    resendSuccess: "Resend Successfully",
  },
  ledgerWalletManagerDepositRecord: {
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    operation: "Operation",
    chain_name: "Chain Name",
    ph_chain_name: "Please select Chain Name",
    currency: "Currency",
    ph_currency: "Please select Currency",
    approval_progress: "Approval Progress",
    client_id: "Client ID",
    ph_client_id: "Please enter Client ID",
    transaction_amount: "Transaction Amount",
    transaction_status: "Transaction Status",
    ph_transaction_status: "Please select Transaction Status",
    request_status: "Request Status",
    ph_request_status: "Please select Request Status",
    reference_no: "Reference No.",
    ph_reference_no: "Please enter Reference No.",
    wallet_address: "Wallet Address",
    ph_wallet_address: "Please enter Wallet Address",
    sending_address: "Sending Address",
    ph_sending_address: "Please enter Sending Address",
    wallet_tag: "Wallet Tag",
    risk_level: "Risk Level",
    ph_risk_level: "Please select Risk Level",
    transaction_hash: "Transaction Hash",
    ph_transaction_hash: "Please enter Transaction Hash",
    auto_approve: "Auto",
    block_no: "Block No.",
    block_hash: "Block Hash",
    block_time: "Block Time",
    on_chain_transaction_status: "On Chain Transaction Status",
    subtitle_1st_box: "General Transaction Information",
    subtitle_2nd_box: "Transaction Payee/Payer Information",
    subtitle_3rd_box: "Chain Information",
    subtitle_4th_box: "Switch to Manual Approval Process",
    subtitle_5th_box: "Approval Operation",
    switched_by: "Switched By",
    switched_time: "Switched Time",
    switching_remarks: "Remarks of Switching",
    showAllRecord: "All",
    showToDoRecord: "To-Do",
    showCompleted: "Completed",
    viewBtn: "View",
    approveBtn: "Approve/Reject",
    approval: "Approval",
    switch_to_manual_approval_process: "Switch to Manual Approval Process",
    switch: "Switch",
    approval_sequence: "Approval Sequence",
    operated_by: "Operated By",
    operation_time: "Operation Time",
    operation_result: "Operation Result",
    remarks: "Remarks",
    approve_success: "Successfully Approved",
    reject_success: "Successfully Rejected",
    switch_success: "Successfully Switched",
    approval_level_1: "1st",
    approval_level_2: "2nd",
    approval_level_3: "3rd",
    approval_level_4: "4th",
  },
  ledgerWithdrawRequest: {
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    chain_name: "Chain Name",
    currency: "Currency",
    receiving_address: "Receiving Address",
    request_status: "Request Status",
    wm_operation_status: "WM Operation Status",
    merchant_order_id: "Merchant Order ID",
    refund_order_id: "Refund Order ID",
    transaction_hash: "Transaction Hash",
    creator_type: "Creator Type",
    ph_chain_name: "Please select Chain Name",
    ph_currency: "Please select Currency",
    ph_sending_address: "Please enter Sending Address",
    ph_receiving_address: "Please enter Receiving Address",
    ph_request_status: "Please select Request Status",
    ph_wm_operation_status: "Please select WM Operation Status",
    ph_merchant_order_id: "Please enter Merchant Order ID",
    ph_refund_order_id: "Please enter Refund Order ID",
    ph_transaction_hash: "Please enter Transaction Hash",
    ph_creator_type: "Please select Creator Type",
    operation: "Operation",
    client_id: "Client ID",
    transaction_amount: "Transaction Amount",
    approval_progress: "Approval Progress",
    auto_approve: "Auto",
    amount_of_fee: "Amount of Fee",
    currency_of_fee: "Currency of Fee",
    created_by: "Created By",
    sending_address: "Sending Address",
    hot_wallet_address: "Hot Wallet Address",
    wallet_tag_of_receiving_address: "Wallet Tag of Receiving Address",
    create_request: "Create Request",
    ph_wallet_tag_of_receiving_address:
      "Please enter Wallet Tag of Receiving Address",
    approveBtn: "Approve/Reject",
    approval: "Approval",
    ph_client_id: "Please enter Client ID",
    ph_transaction_amount: "Please enter Transaction Amount",
    success_create: "Created withdraw request Successfully",
    approval_sequence: "Approval Sequence",
    operated_by: "Operated By",
    operation_time: "Operation Time",
    operation_result: "Operation Result",
    remarks: "Remarks",
    approve_success: "Request Approved successfully",
    reject_success: "Request Rejected successfully",
    create_warning: "Please %{verb} %{field}",
    asset_name: "Currency",
    select: "select",
    enter: "enter",
    approval_level_1: "1st",
    approval_level_2: "2nd",
    approval_level_3: "3rd",
    approval_level_4: "4th",
    available_balance: "Available Balance: %{balance} %{assetName}",
    available_client_balance:
      "Client Available Balance: %{balance} %{assetName}",
    must_be_larger_than_zero: "%{fieldName} must be larger than 0",
  },
  ledgerFreezeRequest: {
    merchant_order_id: "Merchant Order ID",
    approvals: "Approvals",
    client_id: "Client Id",
    chain_name: "Chain Name",
    currency: "Currency",
    amount: "Amount",
    seq: "Seq",
    frozen_order_id: "Frozen Order ID",
    related_order_id: "Related Order ID",
    status: "Please select Status",
    created_by: "Created By",
    creator_type: "Creator Type",
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    remarks: "Remarks",
    error_reason: "Error Reason",
    ph_chain_name: "Please select Chain Name",
    ph_currency: "Please select Currency",
    ph_status: "Please select Status",
    ph_merchant_order_id: "Please select Merchant Order ID",
    ph_client_id: "Please select Client Id",
    ph_frozen_order_id: "Please select Frozen Order ID",
    ph_related_order_id: "Please select Related Order ID",
  },
  ledgerFrozenFund: {
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    frozen_time: "Frozen Time",
    unfrozen_time: "Unfrozen Time",
    chain_name: "Chain Name",
    currency: "Currency",
    merchant_order_id: "Merchant Order ID",
    status: "Status",
    client_id: "Client Id",
    ph_chain_name: "Please select Chain Name",
    ph_currency: "Please select Currency",
    ph_status: "Please select Status",
    ph_merchant_order_id: "Please select Merchant Order ID",
    ph_client_id: "Please select Client Id",
    frozen_amount: "Amount",
    frozen_date: "Frozen Date",
    unfrozen_date: "Unfrozen Date",
    Frozen: "Frozen",
    Unfrozen: "Unfrozen",
  },
  ledgerTransferRequest: {
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    chain_name: "Chain Name",
    currency: "Currency",
    request_status: "Request Status",
    merchant_order_id: "Merchant Order ID",
    creator_type: "Creator Type",
    ph_chain_name: "Please select Chain Name",
    ph_currency: "Please select Currency",
    ph_request_status: "Please select Request Status",
    ph_merchant_order_id: "Please enter Merchant Order ID",
    ph_creator_type: "Please select Creator Type",
    operation: "Operation",
    sending_client_id: "Sending Client ID",
    receiving_client_id: "Receiving Client ID",
    ph_sending_client_id: "Please enter Sending Client ID",
    ph_receiving_client_id: "Please enter Receiving Client ID",
    transaction_amount: "Transaction Amount",
    approval_progress: "Approval Progress",
    auto_approve: "Auto",
    amount_of_fee: "Amount of Fee",
    currency_of_fee: "Currency of Fee",
    created_by: "Created By",
    approval_sequence: "Approval Sequence",
    operated_by: "Operated By",
    operation_time: "Operation Time",
    operation_result: "Operation Result",
    remarks: "Remarks",
    create_request: "Create Request",
    ph_transaction_amount: "Please enter Transaction Amount",
    create_warning: "Please %{verb} %{field}",
    select: "select",
    enter: "enter",
    asset_name: "Currency",
    approveBtn: "Approve/Reject",
    approval: "Approval",
    approve_success: "Request Approved successfully",
    reject_success: "Request Rejected successfully",
    success_create: "Created transfer request Successfully",
    approval_level_1: "1st",
    approval_level_2: "2nd",
    approval_level_3: "3rd",
    approval_level_4: "4th",
    receiving_and_sending_client_same:
      "Sending Client ID and Receiving Client ID must not be the same",
    must_be_larger_than_zero: "%{fieldName} must be larger than 0",
  },
  withdrawApprovalSetting: {
    add_item: "Add Item",
    add_record: "Add Record",
    after_amt: "After Edit",
    alert_creator_approver_same:
      "The operator and creator must not be the same",
    alert_no_approval_permission: "No relevant permission",
    amountDidntChange:
      "There is no change in the amount. Please modify the amount",
    amountIslargerThan: "When Total Amount Is Larger Than",
    approveOrReject: "Approve/Reject",
    asset_name: "Currency",
    assetIsEmpty: "Please Select Currency",
    approval_status: "Approval Status",
    batch_id: "Batch ID",
    before_amt: "Before Edit",
    chain_asset: "Currency",
    chain_name: "Chain Name",
    chainNameIsEmpty: "Please Select Chain Name",
    clickToApprove: "Approve",
    ConfirmApprove: "Request Approved",
    ConfirmReject: "Request Rejected",
    created_by: "Created By",
    created_date: "Creation Time",
    creation_time: "Creation Time",
    edit_record: "Edit Record",
    edit_request_created: "Edit Request created",
    from_address: "Sending Address",
    hourlyLimistMustBeGreaterThanAmtInRegulation:
      'The value of "Max. Auto Approve Limit Within %{time} Hour" must be equal to or greater than the amount in approval regulations "',
    last_modified_date: "Last Modified Time",
    limit_inputBox_description: "if there is no limit, no need to input",
    max_hourly_approve_limit: "Max. Auto Approve Limit Within %{time} Hour",
    maximumOfApprovers: "The maximum no. of approver is 4",
    mx_addr_hrly_count:
      "Receiving Address Max. No. of Transaction within %{time} Hour",
    mx_addr_hrly_limit:
      "Receiving Address Max. Transaction Amount within %{time} Hour",
    mx_hourly_limit: "Max. Limit Within %{time} Hour",
    noOfApprover: "No. of Approver",
    onlyAllowDeleteTheLargesNo:
      "Please remove from the item with the largest no. of approver",
    operated_by: "Operated By",
    operation_time: "Operation Time",
    operation: "Operation",
    ph_settle: "Optional",
    phApproval_status: "Please Select Status",
    phAsset_name: "Please select Currency",
    phAutoApproval_limit:
      "Please enter the Max. Auto Approve Limit Within %{time} Hour (Optional)",
    phChain_name: "Please select Chain Name",
    phMx_addr_hrly_count:
      "Please enter Receiving Address Max. No. of Transaction within %{time} Hour (Optional)",
    phMx_addr_hrly_limit:
      "Please enter Receiving Address Max. Transaction Amount within %{time} Hour (Optional)",
    phMx_hourly_limit:
      "Please enter the Max Limit Within %{time} Hour (Optional)",
    phProposal_no: "Please enter Proposal No.",
    pleaseEnterSettleMentAmt:
      "Please enter the Settlement limit in approval regulation",
    pleaseEnterWithDrawAmt:
      "Please enter the Withdraw limit in approval regulation ",
    pleaseEnterWithdrawAmtAndSettlementAmt:
      "Please enter the Withdraw limit and the Settlement limit in approval regulation",
    previousSettleLimitIsEmpty:
      "Please enter the Settlement limit in approval regulation",
    previousWithdrawLimitIsEmpty:
      "Please enter the Withdraw limit in approval regulation",
    proposal_no: "Proposal No.",
    recordExist: "The record already exists",
    regulation: "Approval Regulation",
    rejectQuestion: "Confirm to reject?",
    reqEditMaxLimitWithin1Hr: "Request Edit Max. Limit Within %{time} Hour",
    reqEditMaxNoTrxAmt:
      "Request Edit Max. Transaction Amount Within %{time} Hour",
    reqEditMaxNoTrxs:
      "Request Edit Max. No. of Transaction Within %{time} Hour",
    request_hourly_limit: "Request Edit Auto Approve Limit Within %{time} Hour",
    request_record: "Request Record",
    resend: "Resend",
    resendSuccess: "Resend Successfully",
    settleAmtMustBeGreater:
      "Please enter the limit for Settlement type that is larger than the limit in previous row",
    settlement: "Settlement",
    status: "Status",
    total_amount: "Total Amount",
    total_count: "Total Count",
    type: "Type",
    typeToSearch: "Type to Search",
    view: "View",
    view_operation: "View Operation",
    view_operation_transaction: "View Operation Transaction",
    withdraw_approval_setting: "Withdraw Approval Setting",
    withdraw: "Withdraw",
    withdrawAmtMustBeGreater:
      "Please enter the limit for Withdraw type that is larger than the limit in previous row",
    withDrawApprovalSettingDialog: "Withdraw Approval Setting",
  },
  withdrawApprovalCheckingTimeSetting: {
    checkingTimeSettingTab: "Checking Time Setting",
    requestRecordTab: "Request Record",
    existingCheckingTime: "Existing Checking Time",
    checkingTime: "Checking Time",
    phCheckingTime: "Please Enter New Checking Time",
    hasPendingRequest: "There is a pending request",

    limited24Hours: "Max. Checking Time is 24 hours",
    no_approval_permission: "No relevant permission",

    edit: "Edit",
    hour: "hour",
    hours: "hours",

    operation: "Operation",
    proposal_no: "Proposal No.",
    approval_progress: "Approval Progress",
    before_edit: "Before Edit",
    after_edit: "After Edit",
    created_by: "Created By",
    creation_time: "Creation Time",
    operated_by: "Operated By",
    operation_time: "Operation Time",

    approve: "Approve",
    approval: "Approval",
    reject: "Reject",
    approve_reject_btn: "Approve/Reject",

    approval_sequence: "Approval Sequence",
    operation_result: "Operation Result",

    approval_status: "Approval Status",
    phProposal_no: "Please Enter Proposal No.",
    phApproval_status: "Please Select Approval Status",
    approve_success: "Successfully Approved",
    reject_success: "Successfully Rejected",
    success: "success",
    alert_creator_approver_same:
      "The operator and creator must not be the same",
    alert_approvers_same: "The approvers must not be the same",
    alert_no_approval_permission: "No relevant permission",
  },
  withdrawRequestRecord: {
    startDate: "Start Date",
    endDate: "End Date",
    created_date: "Creation Time",
    status: "Status",
    chain_name: "Chain Name",
    asset_name: "Currency",
    batch_id: "Batch ID",
    merchant_order_id: "Merchant Order ID",
    ph_status: "Please select Status",
    ph_chain_name: "Please select Chain Name",
    ph_asset_name: "Please select Currency",
    ph_batch_id: "Please enter Batch ID(multiple selection)",
    ph_merchant_order_id: "Please enter Merchant Order ID",
    from_address: "Sending Address",
    total_amount: "Total Amount",
    total_count: "Total Count",
    created_by: "Created By",
    last_modified_date: "Last Modified Time",
    view_operation: "View Operation",
    view_operation_transaction: "View Operation Transaction",
    resend: "Resend",
    operation: "Operation",
    resendSuccess: "Resend Successfully",
    miner_fee_currency: "Currency of Miner Fee",
    miner_fee: "Miner Fee",
    actual_amount: "Actual Amount",
  },
  assetManagement: {
    invoker_wallet_address: "Invoker Wallet Address",
    miner_fee_asset_name: "Currency of Miner Fee",
    estimated_miner_fee: "Estimated Miner Fee",
    prepared_miner_fee: "Prepared Miner Fee",
    last_estimated_miner_fee_date: "Last Estimated Time",
    re_estimate: "Re-estimate",
    estimating: "Estimating",
    risk_level: "Risk Level",
    acceptant_risk_level: "Acceptant Risk Level",
    ph_batch_sweep_risk_level: "Please select Risk Level",
    ph_batch_sweep_acceptant_risk_level:
      "Please select Acceptant Risk Level (Multiple Selection)",
    ph_batch_sweep_acceptant_risk_level_warning:
      "Please select Acceptant Risk Level",
    view: "View",
    chain_name_and_currency: "Chain Name / Currency",
    max_address_no: "Max. Selectable Address",
    address_selected: "Selected Address",
    max_sweepable_amount: "Max. Sweepable Amount",
    amount_to_sweep: "Sweep Amount",
    select_all: "Select All",
    specific_sweep_amount: "Specific Sweep Amount",
    ph_specific_sweep_amount: "Please enter Specific Amount",
    ph_to_sweep_amount: "Please enter Sweep Amount",
    ph_max_sweepable_address: "Please enter Max. No. of Sweepable Address",
    auto_cal: "Calculate",
    excessive_amt_warning:
      "The Specific Sweep Amount must not be larger than the total of Sweepable Amount",
    bigger_than_0_warning: "Sweep Amount must be larger than 0",
    no_invoker_address_warning:
      "please select invoker wallet address for sweep request",
    no_address_warning: "At least select one address for sweep request",
    total_sweep_amount: "Total",
    accumative: "Cumulative Amount",
    max_sweepable_address: "Max. No. of Sweepable Address",
    accumativeAmt: "accumativeAmt",
    ph_status: "Please select Status",
    phProposal_no: "Please enter Proposal No.",
    proposal_no: "Proposal No.",
    error_reason: "Error Reason",
    rescan: "Rescan",
    rescan_transaction_succeeded: "Rescan Transaction Succeeded",
    step_seq: "Step Sequence",
    tx_seq: "Transaction Sequence",
    tx_status: "Transaction Status",
    tx_hash: "Transaction Hash",
    no_approval_permission: "No relevant permission for approval",
    ph_approval_completed: "Please enter No. of Account Approved",
    last_executed_by: "Last Executed By",
    last_executed_date: "Last Executed Time",
    transactionApproved: "Transaction Approved",
    transactionRejected: "Transaction Rejected",
    transactionExecuted: "Transaction Executed",
    execute: "Execute",
    all: "All",
    position1: "1st",
    position2: "2nd",
    position3: "3rd",
    position4: "4th",
    remark: "Remarks",
    total_operation: "Total Operations",
    phMerchantOrderIds: "Please enter Merchant Order ID",
    approval_progress: "Approval Progress",
    operation_type: "Operation Type",
    phOperation_type: "Please select Operation Type",
    creator_type: "Creator Type",
    phCreator_type: "Creator type",
    seq: "Approval Sequence",
    approval_result: "Operation Result",
    operated_date: "Operation Time",
    approval_completed: "No. of Account Approved",
    tag: "Tag",
    approve: "Approve",
    approval: "Approval",
    approval_history: "Approval History",
    approvalContent:
      "Please confirm whether you wish to Approve or Reject this Transaction",

    max_hourly_approve_limit:
      "Max. Auto Approve Withdraw Limit Within %{time} Hour",
    approval_threshold_amount:
      "Auto Approve When Withdraw Amount Less Than or Equal to",
    asset_names: "Currency",
    operation: "Operation",
    edit: "Edit",
    edit_record: "Edit Record",
    add_record: "Add Record",
    chain_asset: "Currency",
    request_record: "Request Record",
    withdraw_approval_setting: "Withdraw Approval Setting",
    create_time: "Creation Time",
    existing_amount: "Amount Before Edited",

    edited_amount: "Request Edit Amount",
    creation_time: "Creation Time",
    operated_by: "Operated By",
    operation_time: "Operation Time",
    phApproval_status: "Please Select Status",
    approval_status: "Status",
    pending: "Pending",
    approved: "Approved",
    rejected: "Rejected",
    same_user_error: "The operator and creator must not be the same",
    withDrawApprovalSettingDialog: "Withdraw Approval Setting",
    rejectQuestion: "Confirm to reject?",

    ConfirmApprove: "Request Approved",
    approveFail: "Failed",
    ConfirmReject: "Request Rejected",
    Success: "Success",
    Fail: "Fail",
    YubiKeyIsEmpty: "YubiKey is Empty",
    reject: "Reject",
    clickToApprove: "Approve",
    amountDidntChange:
      "There is no change in the amount. Please modify the amount",
    recordExist: "The record already exists",
    edit_request_created: "Edit Request created",
    approveOrReject: "Approve/Reject",

    available_balance: "Available Balance",
    created_by: "Created By",
    confirm: "Confirm",
    cancel: "Cancel",
    cannot_empty: "Wallet Address or Withdraw Amount must not be empty",
    sweepableAmount: "Sweepable Amount",
    minimum_reserve_requirement: "The current minimum reserve requirement is ",
    preview_warning:
      "For %{asset}, threshold must be larger than %{amount} as the current minimum reserve requirement is %{amount}",
    withdraw_succeed: "Withdraw Succeed",
    total_count: "Total Count",
    download_template: "Download Template",
    import_excel: "Import Excel",
    total_amount: "Total Amount",
    submit: "Submit",
    wallet_address: "Wallet Address",
    withdraw_amount: "Withdraw Amount",
    add_item: "Add Item",
    phFrom_address: "Sending Address",
    table: "table",
    batchSweep: "Batch Sweep",
    sweepRequestRecord: "Sweep Request Record",
    searchFilter: "Search Filter",
    assetNotSupported: "Asset is not supported",
    chain_name: "Chain Name",
    asset_name: "Currency",
    threshold: "Threshold",
    preview: "Preview",
    decimals: "Decimals",
    totalCount: "Total Count",
    totalAmount: "Total Amount",
    coinSweep: "Coin Sweep",
    cryptSweeped: "Crypto Sweeped",
    walletVersion: "Wallet Version",
    address: "Address",
    from_address: "Sending Address",
    to_address: "Receiving Address",
    invoker_address: "Invoker Address",
    batch_id: "Batch ID",
    created_date: "Creation Time",
    last_modified_date: "Last Modified Time",
    amount: "Amount",
    status: "Status",
    search: "Search",
    reset: "Reset",
    path: "Path",
    viewOperation: "View Operation",
    viewOperationTransaction: "View Operation Transaction",
    startDate: "Start Date",
    endDate: "End Date",
    noWalletAddressError: "No relevant receiving address",
    withdrawRequestRecord: "Withdraw Request Record",
    wallet_version: "Wallet Version",
    phChain_name: "Please select Chain Name",
    phAsset_name: "Please select Currency",
    ph_wallet_type: "Please select Wallet Type",
    wallet_type: "Wallet Type",
    phStatus: "Please select Status",
    phBatch_id: "Please enter Batch ID(multiple selection)",
    phAddress: "Please select Address",
    phThreshold: "Please enter Threshold",
    export: "Export",
    merchant_order_id: "Merchant Order ID",
    phMerchant_id: "Please enter Merchant Order ID",
    phAutoApproval_limit:
      "Please enter the Max. Auto Approve Limit Within %{time} Hour (Optional)",
    existing_hourly_limit_before_edit:
      "Max. Auto Approve Limit Within %{time} Hour",
    request_hourly_limit:
      "Request Edit Auto Approve Withdraw Limit Within %{time} Hour",
    limit_inputBox_description: "if there is no limit, no need to input",
    merchant_operation: "Merchant Operation",
    api_withdraw_tab: "API Withdraw",
    portal_withdraw_tab: "Portal Withdraw",
    sweep_tab: "Sweep",
    showAllRecord: "All",
    showToDoRecord: "TO-Do",
    showCompleted: "Completed",
    noOfApprover: "No. of Approver",
    amountIslargerThan: "When Total Amount Is Larger Than",
    regulation: "Approval Regulation",
    maximumOfApprovers: "The maximum no. of approver is 4",
    onlyAllowDeleteTheLargesNo:
      "Please remove from the item with the largest no. of approver",
    alertEmptyInput: "Please enter the amount in approval regulation",
    alertValueMustGreaterThan:
      "Please enter an amount that is larger than the amount in previous row",
    alertValueCannotBeTheSameAsExisitngAmount:
      'Amount in Approval Regulations must be larger than the "Auto Approve When Amount Less Than or Equal to"',
    approver: "No.",
    amountBeforeEdit: "Amount Before Edited",
    requestEditAmount: "Request Edit Amount",

    hourlyLimistMustBeGreaterThanAmtInRegulation:
      'The value of "Max. Auto Approve Limit Within %{time} Hour" must be equal to or greater than the amount in approval regulations "',
    assetIsEmpty: "Please Select Currency",
    chainNameIsEmpty: "Please Select Chain Name",
    before_amt: "Before Edit",
    after_amt: "After Edit",
    reqEditMaxLimitWithin1Hr:
      "Request Edit Max. Withdraw Limit Within %{time} Hour",
    reqEditMaxNoTrxs:
      "Request Edit Max. No. of Withdraw Transaction Within %{time} Hour",
    reqEditMaxNoTrxAmt:
      "Request Edit Max. Withdraw Transaction Amount Within %{time} Hour",
    phMx_hourly_limit:
      "Please enter the Max Limit Within %{time} Hour (Optional)",
    phMx_addr_hrly_count:
      "Please enter Receiving Address Max. No. of Transaction within %{time} Hour (Optional)",
    phMx_addr_hrly_limit:
      "Please enter Receiving Address Max. Withdraw Transaction Amount within %{time} Hour (Optional)",
    noOfTrxsBeforeCreateTimeWithinOneHr:
      "No. of Transaction Before (%{checkingHour} hr Before Creation Time To Current)",
    totalTrxsAmtWithinTwentyFourhr: "Total Transaction Amount(24hrs)",
    totalTrxsAmtWithOneHr:
      "Total Transaction Amount (%{checkingHour} hr Before Creation Time to Current) ",
    receivingAddress: "Receiving Addres",
    //
    mx_hourly_limit: "Max. Withdraw Limit Within %{time} Hour",
    mx_addr_hrly_count:
      "Receiving Address Max. No. of Withdraw Transaction within %{time} Hour",
    mx_addr_hrly_limit:
      "Receiving Address Max. Withdraw Transaction Amount within %{time} Hour",
    //
    total: "Total",
    auto_approval_disable: "System only allow manual approval at this moment.",
    auto_approval_amount_threshold:
      "'Auto Approve When Amount Less Than or Equal to' %{chain_name} is %{configAmt} %{asset_name} at the moment. Transaction amount exceeded %{configAmt} %{asset_name}.",
    auto_approval_hourly_limit_amount:
      "'Max. Auto Approve Limit Within %{time} Hour'(%{chain_name}) is %{configAmt} %{asset_name} at the moment. The sum of auto-approved transaction amount within %{time} hour was %{checking_value} %{asset_name} when the request is created.",
    all_approval_hourly_limit_amount:
      "'Max. Limit Within %{time} Hour' (%{chain_name}) is %{configAmt} at the moment. The sum of transaction amount within %{time} hour was %{checking_value} %{asset_name} when the request is created",
    to_address_hourly_limit_amount:
      "'Receiving Address Max. Transaction Amount within %{time} Hour'(%{chain_name}) was %{configAmt} %{asset_name} at the moment. The sum of transaction amount of receiving address (%{receivingAddress}) was %{checking_value} %{asset_name} when the request is created.",
    to_address_hourly_limit_count:
      "'Receiving Address Max. No. of Transaction within %{time} Hour'(%{chain_name}) was %{configAmt} at the moment. The no. of transaction within %{time} hour of receiving address (%{receivingAddress}) was %{checking_value} when the request is created.",
    prepare_txn_retry: "Prepare Transaction Failed",
    exceeded_estimated_miner_fee: "%{configAmt}% Exceeded Estimated Miner Fee",
    exceeded_resubmit_hour_limit: "Resubmit over %{configAmt} hours",
    manual_approval: "Manual Approval Reason",
    approval_op: "Approval Operation",
    withdraw: "Withdraw",
    settlement: "Settlement",
    type: "Type",
    pleaseEnterSettleMentAmt:
      "Please enter the Settlement limit in approval regulation",
    pleaseEnterWithDrawAmt:
      "Please enter the Withdraw limit in approval regulation ",
    pleaseEnterWithdrawAmtAndSettlementAmt:
      "Please enter the Withdraw limit and the Settlement limit in approval regulation",
    withdrawAmtMustBeGreater:
      "Please enter the limit for Withdraw type that is larger than the limit in previous row",
    settleAmtMustBeGreater:
      "Please enter the limit for Settlement type that is larger than the limit in previous row",
    sequence: "Serial No.",
    import_address_error:
      "Imported addresses do not match with addresses in the system",
    minLessThanOne: "Minimum number of sweepable address must be larger than 1",
    maxLargerThanOneThousand: "Maximum number of sweepable address is 1000",
    previousSettleLimitIsEmpty:
      "Please enter the Settlement limit in approval regulation",
    previousWithdrawLimitIsEmpty:
      "Please enter the Withdraw limit in approval regulation",
    allowIntOnly: "Max. No. of Sweepable Address must be integer",
    ph_settle: "Optional",
    alert_creator_approver_same:
      "The operator and creator must not be the same",
    alert_approvers_same: "The approvers must not be the same",
    alert_no_approval_permission: "No relevant permission",
    rebroadcast: "Rebroadcast",
    rebroadcast_transaction_succeeded: "Rebroadcast Transaction Succeeded",
    last_modified_time: "Last Modified Time",
    include_no_risk_level_wallet_filter: "This Address",
    risk_level_count: "Numbers of Transactions with No Risk Level Data",
    miner_fee_currency: "Currency of Miner Fee",
    miner_fee: "Miner Fee",
    actual_amount: "Actual Amount",
    wallet_settlement_time: "Wallet Settlement Time",
  },
  walletManagement: {
    wb_should_not_be_zero_as_zero_is_excluded:
      "Wallet Balance should not be zero as zero is excluded",
    wallet_balance_filter_has_been_disabled:
      "Wallet Balance filter has been disabled",
    wb_both_fields_in_wallet_balance_are_required:
      "Both fields in Wallet Balance are required",
    exclude_zero: "Exclude Zero",
    wallet_balance: "Wallet Balance",
    ph_wallet_balance: "Please enter Wallet Balance",
    ph_control_groups: "Adjust Control Group",
    unassign: "unassign",
    client: "client",
    settlement: "settlement",
    tx_hash: "Transaction Hash",
    assetNotSupported: "Asset is not supported",

    table: "table",
    searchFilter: "Search Filter",
    hotWallet: "Hot Wallet",
    clientWallet: "Client Wallet",
    merchant_id: "Merchant ID",
    client_id: "Client ID",
    chain_id: "Chain ID",
    chain_type: "Chain Type",
    chain_name: "Chain Name",
    wallet_type: "Wallet Type",
    is_default: "Default Wallet",
    set_wallet_to_default: "Set Wallet To Default",
    submitBtn: "Submit",
    accountNo: "Wallet Name",
    walletStatus: "Wallet Status",
    status: "Status",
    enabled: "Enabled",
    disabled: "Disabled",
    deleted: "Deleted",
    unassigned: "Unassigned",
    confirm: "Confirm",
    cancel: "Cancel",
    export: "Export",
    search: "Search",
    reset: "Reset",
    assignWalletSuccess: "Assigned wallet successfully",
    wallet_address_already_existed: "Wallet Address already existed",
    walletName: "Wallet Name",
    walletBalanceAdjustment: "Wallet Balance Adjustment",
    balance: "Wallet Balance",
    asset_name: "Currency",
    created_date: "Creation Time",
    last_modified_date: "Last Modified Time",
    set_default: "Set Default",
    currentWalletBalance: "Current Wallet Balance",
    adjustAmountTo: "Adjust Amount to",
    lastTransactionDate: "Last Transaction Date",
    wallet_version: "Wallet Version",
    accountNoError: "Please enter an Account Number",
    chainTypeError: "Please select a Chain Name",
    chainTypeSeriesError: "Please select Chain Name with the same series",
    chainIdError: "Please select a Chain ID",
    currencyError: "Please select a Currency",
    walletTypeError: "Please select a Wallet Type",
    walletAddressError: "Please enter a Wallet Address",
    adjustmentAmountError: "Please enter an Adjustment Amount",
    walletNameError: "Please enter a Wallet Name",
    displayNameError: "Please enter a Display Name",
    merchantIdError: "Please enter a Merchant ID",
    remarksError: "Please enter remark",
    walletStatusError: "Please select a Wallet Status",
    invalidAddressError: "Please enter a Valid Wallet Address",
    adjustAmountError: "Please enter the Adjustment Amount",
    clientIdError: "Please enter a Client Id",
    operation: "Operation",
    enableOrDisable: "Enabled/Disabled",
    approvalProposalNo: "Proposal No.",
    createAddress: "Assign Wallet Address",
    wallet_name: "Wallet Name",
    display_name: "Display Name",
    wallet_address: "Wallet Address",
    wallet_tag: "Wallet Tag",
    x_address: "x_address", // TODO: wait for requirement update
    walletAddress: "Wallet Address",
    created_by_email: "Created By",
    approved_user: "Operared By",
    approved_date: "Operation Time",
    approval_status: "Approval Status",
    pending: "Pending",
    approve: "Approve",
    reject: "Reject",
    approved: "Approved",
    rejected: "Rejected",
    creationTime: "Creation Time",
    approvalTime: "Operation Time",
    creationTimeStart: "Creation Time Start",
    creationTimeEnd: "Creation Time End",
    approvalTimeStart: "Operation Time Start",
    approvalTimeEnd: "Operation Time End",
    startTime: "Start Time",
    endTime: "End Time",
    amount: "Amount",
    addBalanceAdjustment: "Add Balance Adjustment",
    approvalDetails: "Approval Details",
    approveOrReject: "Approve/Reject",
    approvePropsalConfirmation: "Confirm to approve the proposal?",
    rejectPropsalConfirmation: "Confirm to reject the proposal?",
    transactionApproved: "Transaction Approved",
    transactionRejected: "Proposal Rejected",
    completed: "Completed",
    amountMin: "Minimum Amount",
    amountMax: "Maximum Amount",
    adjustBalanceSuccess: "A new balance adjustment proposal has been added",
    futureWalletBalance: "Future Wallet Balance",
    proposal_no: "Proposal No.",
    date_created: "Creation Time",
    adjustment_amount: "Adjustment Amount",
    apiError: "Error calling API",
    checkBalanceError: "Cannot find Wallet to Check Balance",
    remark: "Remarks",
    clientTagError: "Please enter a Client Id",
    phApprovalProposalNo: "Please enter Proposal No.",
    phWallet_name: "Please enter Wallet Name",
    phDisplay_name: "Please enter Display Name",
    phWalletName: "Please enter Wallet Name",
    phClientId: "Please enter Client ID",
    phDisplayName: "Please enter Display Name",
    phChain_name: "Please select Chain Name",
    phAsset_name: "Please select Currency",
    phWallet_address: "Please enter Wallet Address",
    phWalletAddress: "Please enter Wallet Address",
    phApproval_status: "Please select Approval Status",
    phStartTime: "Start Time",
    phEndTime: "End Time",
    phClient_id: "Please enter Account No.",
    phWalletStatus: "Please select Wallet Status",
    phWalletType: "Please select Wallet Type",
    phWalletSingleType: "Please select Wallet Type",
    phWallet_type: "Please select Wallet Type",
    latest_checkpoint_id: "Latest Checkpoint ID",
    latest_checked_blocked_no: "Latest Checked Block No.",
    certified_with_on_chain_balance: "Certified with On-Chain Balance",
    last_certified_checkpointId: "Last Certified Checkpoint ID",
    is_certified_balance: "Certified with On-Chain Balance",
    ph_certified_balance: "Please select Certified Balance",
    same_user_error: "The operator and creator must not be the same",
    phTo_addr_limit: "",
    phMx_hourly_limit: "",
    phHourly_auto_approval_limt: "",
    phTo_addr_count: "",
    control_groups_type_client: "Client",
    control_groups_type_settlement: "Settlement",
    control_groups_type_unassigned: "Unassigned",

    // Edit Display Name
    edit_wallet_name: "Edit Display Name",
    existing_display_name: "Existing Display Name",
    new_display_name: "New Display Name",
    edit_display_name_placeholder: "Please Enter New Display Name",
    empty_display_name_error: "Please Enter New Display Name First",
    edited_display_name_message: "Edited Display Name",

    // wallet tabs
    wallet_management_tab: "Wallet Management",
    request_record_tab: "Request Record",

    addAddressBook: "Import Address",
    observeWalletError: "Observe Address",
    observe_address: "Observe Address",
    delete_record: "Delete",
    confirm_delete_wallet_record: "Confirm to delete wallet record?",
    deleted_wallet_record: "Deleted Wallet Record",

    yubikey_id: "YubiKey ID",
    ph_yubikey_id: "Please enter Yubikey ID",
    request_address: "Request Address",
    created_by: "Created By",
    creation_time: "Creation Time",
    operated_by: "Operated By",
    operation_time: "Operation Time",
    enable: "Enable",
    disable: "Disable",
    success: "Success",
    requested_address: "Requested Address",
    ph_requested_address: "Please Enter Requested Address",
    approval: "Approval",
    approval_progress: "Approval Progress",
    no_approver_permission: "No Approver Permission",
    no_rejecter_permission: "No Rejecter Permission",
    approve_success: "Successfully Approved",
    reject_success: "Successfully Rejected",
    request_record_created: "Request record created",
    request_record_failed: "Fail to create request record",

    edit_wallet_record: "Edit Wallet Record",

    supported_chains: "Supported Chains",
    chainTypeDifferent: "Please Select Chain with Previous Chain Type",
    proposal_type: "Proposal Type",
    phProposalType: "Please Select Proposal Type",
    phAddressBookSearch: "Search Display Name or Wallet Address",
    allWallet: "All",

    confirmBox: "Confirm",
    enableQuestion: "Confirm to enable the wallet ?",
    disableQuestion: "Confirm to disable the wallet?",

    emptySearchFieldNotice: "Please enter Display Name or Wallet Address",
    searchResultSingleCount: "Display %{display} of %{total} result",
    searchResultMultipleCounts: "Display %{display} of %{total} results",
    transactionSuccess: "Proposal Approved",

    noChangesMade: "Please edit the fields",
    alert_creator_approver_same:
      "The operator and creator must not be the same",
    alert_approvers_same: "The approvers must not be the same",
    alert_no_approval_permission: "No relevant permission",
    approveAndRejectButton: "Approve/Reject",
    risk_level: "Risk Level",
    ph_risk_level: "Please select Risk Level (multiple selection)",
    assignWalletTime: "Assign Wallet Time",
    wallet_settlement_time: "Wallet Settlement Time",
  },

  report: {
    risk_level: "Risk Level",
    ph_risk_level: "Please select Risk Level (multiple selection)",
    batch_id: "Batch ID",
    phBatch_id: "Please enter Batch ID(multiple selection)",
    date_range_exceed: "The date range cannot exceed %{months} months",
    summary_report: "Summary Report",

    table: "table",
    searchFilter: "Search Filter",
    search: "Search",
    export: "Export",
    cancel: "Cancel",
    operation: "Operation",
    merchant_id: "Merchant ID",
    chain_id: "Chain ID",
    wallet_name: "Wallet Name",
    display_name: "Display Name",
    created_date: "Creation Time",
    wallet_type: "Wallet Type",
    client_id: "Client ID",
    chain_name: "Chain Name",
    trans_type: "Transaction Type",
    direction: "Direction",
    status: "Status",
    completed: "Completed",
    wallet_address: "Wallet Address",
    from_address: "Sending Address",
    to_address: "Receiving Address",
    asset_name: "Currency",
    asset_names: "Currency",
    amount: "Transaction Amount",
    trans_fee: "Miner Fee",
    post_balance: "Post Balance of Wallet Address",
    wallet_settlement_date: "Wallet Settlement Time",
    is_fee: "Fee Record",
    isFeeRecord: "Fee Record",
    last_modified_date: "Last Modified Time",
    chain_type: "Chain Type",
    tx_hash: "Transaction Hash",
    view: "View Transaction",
    opening_balance: "Opening Balance",
    in: "In",
    out: "Out",
    allTransactions: "All Transactions",
    totalAmount: "Total Amount",
    confirmedfail: "Confirmed Fail",
    unconfirmed: "Unconfirmed",
    confirmedsuccess: "Confirmed Success",
    invalid: "Invalid",
    reset: "Reset",
    clientDeposit: "Client Deposit",
    withdrawDeposit: "Deposit From Merchant Hot Wallet",
    clientWalletTop: "Client Wallet Topup",
    confirmed: "Confirmed",
    pending: "Pending",
    openingBalance: "Opening Balance",
    sweetToHotWallet: "Sweep To Hot Wallet",
    sweep: "Sweep",
    inOps: "(IN)",
    outOps: "(OUT)",
    accountNoError: "Please enter an Account Number",
    chainNameError: "Please select a Chain Name",
    currencyError: "Please select a Currency",
    transTypeError: "Please select a Transaction Type",
    transStatusError: "Please select a Transaction Status",
    walletAddressError: "Please enter a Wallet Address",
    toAddressError: "Please enter a Sending Address",
    fromAddressError: "Please enter a Receiving Address",
    txHashError: "Please enter a Transaction Hash",
    isFeeError: "Fee Record",
    closingBalance: "Closing Balance",
    creationTimeStart: "Creation Time Start",
    in_client_wallet_topup: "In Client Wallet Topup",
    creationTimeEnd: "Creation Time End",
    modificationTimeStart: "Modification Time Start",
    modificationTimeEnd: "Modification Time Start",
    block_hash: "Block Hash",
    block_number: "Block No.",
    trans_date: "Block Transaction Time",
    confirmation: "Block Confirmation",
    generalInfo: "General Transaction Information",
    payerPayeeInfo: "Transaction Payee/Payer Information",
    baseEnvInfo: "Chain Information",
    date: "Date",
    in_hot_wallet_deposit: "In Hot Wallet Deposit",
    in_sweep_to_hot_wallet: "In Sweep To Hot Wallet",
    in_hot_wallet_transfer: "In Hot Wallet Transfer",
    in_hot_wallet_topup: "In Hot Wallet Topup",
    in_provision_withdraw: "In Provision Withdraw",
    in_withdraw: "In Withdraw",
    in_invoker_deposit: "In Invoker Deposit",
    in_invoker_transfer: "In Invoker Transfer",
    in_merchant_deposit: "In Merchant Deposit",
    in_sweep: "In Sweep",
    in_merchant_transfer: "In Merchant Transfer",
    in_settlement: "In Settlement",
    in_external_transfer: "In External Transfer",
    in_settlement_to_hot_wallet: "In Settlement to Hot Wallet",
    in_settlement_to_invoker_wallet: "In Settlement to Invoker Wallet",
    in_settlement_to_sweep_dest_wallet: "In Settlement to Sweep Dest Wallet",
    "in_settlement-deposit": "In Settlement-Deposit",
    in_balance_adjustment: "In Balance Adjustment",
    in_caution: "In Caution",
    out_withdraw: "Out Withdraw",
    out_merchant_withdraw: "Out Merchant Withdraw",
    out_withdraw_deposit: "Out Withdraw Deposit",
    out_client_wallet_topup: "Out Client Wallet Topup",
    out_hot_wallet_transfer: "Out Hot Wallet Transfer",
    out_hot_wallet_topup: "Out Hot Wallet Topup",
    out_provision_for_withdraw: "Out Provision For Withdraw",
    out_invoker_transfer: "Out Invoker Transfer",
    out_merchant_transfer: "Out Merchant Transfer",
    out_client_deposit: "Out Client Deposit",
    out_hot_wallet_deposit: "Out Hot Wallet Deposit",
    out_invoker_deposit: "Out Invoker Deposit",
    out_settlement: "Out Settlement",
    out_external_transfer: "Out External Transfer",
    out_withdraw_fee: "Out Withdraw Fee",
    out_topup_fee: "Out Topup Fee",
    out_merchant_withdraw_fee: "Out Merchant Withdraw Fee",
    out_balance_adjustment: "Out Balance Adjustment",
    out_caution: "Out Caution",
    in_client_deposit: "In Client Deposit",
    in_withdraw_deposit: "In Withdraw Deposit",
    out_sweep_to_hot_wallet: "Out Sweep to Hot Wallet",
    out_sweep: "Out Sweep",
    out_sweep_fee: "Out Sweep Fee",
    startDate: "Start Date",
    endDate: "End Date",
    clientReport: "Client Report",
    dailyReport: "Daily Report",
    customReport: "Custom Periodic Report",
    ref_no: "Reference No.",
    copied: "Copied!",
    phStatus: "Status",
    phChain_name: "Please select Chain Name",
    phAsset_name: "Please select Currency",
    phWallet_type: "Please select Wallet Type",
    phWallet_name: "Please enter Wallet Name",
    phDisplayName: "Please enter Display Name",
    phWallet_address: "Please enter Wallet Address",
    phClient_id: "Please enter Account No.",
    phDirection: "Please select Direction",
    phTrans_type: "Please select Transaction Type",
    phFrom_address: "Please enter Sending Address",
    phTo_address: "Please enter Receiving Address",
    phIs_fee: "Please select Fee Record",
    phTx_hash: "Please enter Transaction Hash",
    phRef_no: "Please enter Reference No.",
    wallet_tag: "Wallet Tag",
    to_wallet_tag: "Wallet Tag of Receiving Address",
    wallet_type_post_balance: "Post Balance of Wallet",
    is_certified_balance: "Certified with On-Chain Balance",
    ph_certified_balance: "Please select Certified Balance",
    check_point_id: "Checkpoint ID",
    certifiedWithOnChainBalance: "Certified with On-Chain Balance",
    merchant_order_id: "Merchant Order ID",
    phMerchant_id: "Please enter Merchant Order ID",
    checkpoint: "Checkpoint",
    reminder: "Please click SEARCH for searching records",
    noRow: "No relevant record exists",
    phDisplay_name: "Please enter Display Name",
    rescan_transaction_succeeded: "Rescan Transaction Succeeded",
    rescan_risk_level_succeeded: "Rescan Risk Level Succeeded",
  },
  tools: {
    totalAmount: "Total Amount",
    ph_BatchStatus: "Please Select Batch Status",
    edit_success: "Edit Successful",
    disable_success: "Disable Successful",
    delete_success: "Delete Successful",
    reject_success: "Reject Successful",
    approve_success: "Approve Success",
    yubi_key_id: "YubiKey ID",
    yubikey_statuses: "YubiKey Status",
    ph_login_email: "Please enter Login Email",
    ph_yubikey_id: "Please enter YubiKey ID",
    ph_yubikey_statuses: "Please select YubiKey Status",
    delete: "Delete",
    confirm_delete: "Confirm to Delete",
    enable: "Enable",
    disable: "Disable",
    id_serial: "Serial No.",
    login_email: "Login Email",
    registered_date: "Registration Time",
    date_created: "Creation Time",
    last_requested: "Last Requested By",
    last_requested_time: "Last Requested Time",
    last_date_operated: "Last Operated Time",
    last_operated_by: "Last Operated By",
    operation: "Operation",
    approve: "Approve",
    approval: "Approval",
    reject: "Reject",
    email: "Email",
    status: "Status",
    edit: "Edit",
    is_certified_balance: "Certified with On-Chain Balance",
    onchain_balance: "On-Chain Balance",
    block_time: "Block Time",
    check_time: "Check Time",
    checkpoint_id: "Checkpoint ID",
    wallet_address: "Wallet Address",
    block_no: "Block No.",
    startDate: "Start Date",
    endDate: "End Date",
    ph_check_point_id: "Please enter Checkpoint ID",
    ph_is_certified_balance: "Please enter Certified with On-Chain Balance",
    ph_wallet_address: "Please enter Wallet Address",
    ph_blockNo: "Please enter Block No.",
    export: "Export",
    re_broadcast: "Rebroadcast",
    confirm_re_broadcast: "Confirm to Rebroadcast?",
    confirm_failure: "Confirm to fail the transaction?",
    batch_id: "Batch ID",
    operation_type: "Operation Type",
    asset_name: "Currency",
    total_amount: "Total Amount",
    total_count: "Total Count",
    no_of_tx: "No. of BroadcastFailed Record",
    batch_status: "Batch Status",
    created_by: "Created By",
    created_date: "Creation Time",
    phBatch_id: "Please enter Batch ID",
    viewOperation: "View Operation",
    viewOperationTransaction: "View Operation Transaction",
    search: "Search",
    reset: "Reset",
    batch_id_is_empty: "batch id is empty",
    broadcast_bacth_id_success: "Broadcasted Batch ID %{batch_id}",
    phAsset_name: "Please select Currency",
    have_yubi_registered: "Your account is already registered with YubiKey!",
    otp: "One Time Password",
    ph_otp: "Please enter One Time Password",
    register: "register",
    register_success: "Register Successful",
    chain_name: "Chain Name",
    block_number: "Block No.",
    rescan: "Rescan",
    rescan_success: "Rescan Successful",
    rescan_fail: "Rescan Failed",
    ph_block_number: "Please enter Block No.",
    phChain_name: "Please select Chain Name",
    tx_hashes: "Transaction Hash",
    ph_trx_hash: "Please enter Transaction Hash",
    trx_hash_rescan_tab: "Transaction Hash Rescan",
    block_rescan_tab: "Block Rescan",
    trxhashFieldIsEmpty: "Please select Chain name and Enter Transaction Hash",
    blockNumberFieldIsEmpty: "Please select Chain name and Enter Block Number",
    confirmFail: "Confirm Failure",
    re_broadcast_tab: "Rebroadcast",
    history_tab: "History",
    currency: "Currency",
    action: "Action",
    result: "Result",
    operated_by: "Operated By",
    operation_time: "Operation Time",
    ph_status: "Please Select Status",
    phAction: "Please Select Action",
    confirmFailureSuccess: "Confirm Failure Batch ID %{batch_id}",
    yubikey_register_tab: "YuBikey Register",
    yubikey_alteration_tab: "YubiKey Change",
    yubikeyID: "YubiKey ID",
    yubikey_status: "YubiKey Status",
    old_otp: "Existing YubiKey OTP ",
    new_otp: "New YubiKey OTP ",
    alter: "Change",
    phOldOtp: "Please Enter Existing YubiKey",
    phNewOtp: "Please Enter New YubiKey",
    completed: "Completed",
    executing: "Executing",
    failed: "Failed",
    partialcompleted: "Partial Completed",
    requested: "Requested",
    signed: "Signed",
    signfailed: "Sign Failed",
    submitted: "Submitted",
    date: "Operation Time",
    phOperation_type: "Please Select Operation Type",
    bound: "Bound",
    pendingApproval: "Pending for Approval",
    register_submitted: "Request is Submitted",
    date_last_modified: "Last Modified Date",
    enterOtpAndNewOtp: "Please enter Existing Or New YubiKey ",
    systemBalance: "System Balance",
    differenceBalance: "Difference Balance",
    Withdraw: "Withdraw",
    Sweep: "Sweep",
    confirmFailure: "Confirm Failure",
    BatchStatus: "Batch Status",
    Last_Update_Time: "Last Update Time",
    address_name: "Address Name",
    address: "Address",
    ph_address_name: "Please Enter Address Name",
    ph_address: "Please Enter Address",
    addAddress: "Add Address",
    creation_time: "Creation Time",
    proposal_type: "Proposal Type",
    phProposal_type: "Please Select Proposal Type",
    approval_status: "Approval Status",
    phApproval_status: "Please Select Approval Status",
    proposal_no: "Proposal No.",
    approval_progress: "Approval Progress",
  },
  userApprovalManagement: {
    first: "first",
    second: "second",
    approval_permission: "Approval Permission",
    approval_progress: "Approval Progress",
    approval_statuses: "Approval Status",
    approval: "Approval",
    approve_success: "Approve Success",
    approve: "Approve",
    approveOrReject: "Approve/Reject",
    approved: "Approved",
    confirm_delete: "Confirm to Delete",
    confirm_to_disable_approval_permission:
      "Confirm To Disable Approval Permission?",
    confirm_to_enable_approval_permission:
      "Confirm To Enable Approval Permission?",
    confirm_to_unbind: "Confirm To Unbind?",
    created_by: "Created By",
    created_date: "Creation Time",
    creation_time: "Creation Time",
    date_created: "Creation Time",
    delete: "Delete",
    disable_success: "Disable Success",
    disable: "Disable",
    email: "Email",
    enable_success: "Enable Success",
    enable: "Enable",
    endDate: "End Date",
    id_serial: "Serial No.",
    last_date_operated: "Last Operated Time",
    last_operated_by: "Last Operated By",
    last_requested_time: "Last Requested Time",
    last_requested: "Last Requested By",
    login_email: "Login Email",
    no_approver_permission: "No %{who} approver permission",
    operated_by: "Operated By",
    operation_result: "Operation Result",
    operation_time: "Operation Time",
    operation: "Operation",
    ph_approval_permission: "Please select Approval Permission",
    ph_approval_statuses: "Please select Approval Status",
    ph_login_email: "Please enter Login Email",
    ph_proposal_type: "Please select Proposal Type",
    ph_yubi_id: "Please enter YubiKey ID",
    ph_yubikey_status: "Please select YubiKey Status",
    proposal_no: "Proposal No.",
    proposal_status: "Approval Status",
    proposal_type: "Proposal Type",
    registered_date: "Registration Time",
    reject_success: "Reject Success",
    reject: "Reject",
    rejected: "Rejected",
    request_record: "Request Record",
    requested_yubi_key_id: "Requested YubiKey ID",
    reset: "Reset",
    same_approver_error: "The approvers must not be the same",
    same_user_error: "The operator and creator must not be the same",
    search: "Search",
    approval_sequence: "Approval Sequence",
    startDate: "Start Date",
    status: "Status",
    unbind_success: "Unbind Success",
    unbind: "Unbind",
    user_approval: "User Approval",
    yn_approval: "Approval Status",
    yubi_id: "YubiKey ID",
    yubikey_id: "YubiKey ID",
    yubi_key_status: "YubiKey Status",
    yubikey_status: "YubiKey Status",
    alert_creator_approver_same:
      "The operator and creator must not be the same",
    alert_approvers_same: "The approvers must not be the same",
    alert_no_approval_permission: "No relevant permission",
  },
  operations: {
    phMerchantOrderIds: "Please enter Merchant Order ID",
    approval_progress: "Approval Progress",

    same_user_error: "The operator and creator must not be the same",
    created_by: "Created By",
    approved_by: "Operated By",
    approved_date: "Operated Time",
    confirm: "Confirm",
    table: "Table",
    operation: "Operation",
    searchFilter: "Search Filter",
    chain_name: "Chain Name",
    tx_hash: "Transaction Hash",
    amount: "Amount",
    asset_name: "Currency",
    created_date: "Creation Time",
    from_address: "Sending Address",
    to_address: "Receiving Address",
    to_wallet_tag: "Wallet Tag of Receiving Address",
    batch_id: "Batch ID",
    operation_batch_status: "Operation Batch Status",
    step_seq: "Step Sequence",
    tx_seq: "Transaction Sequence",
    tx_status: "Transaction Status",
    operation_type: "Operation Type",
    operation_seq: "Operation Sequence",
    operationRecords: "Operation Records",
    merchant_order_id: "Merchant Order ID",
    phMerchant_id: "Please enter Merchant Order ID",
    path: "Path",
    invoker_address: "Invoker address",
    wallet_version: "Wallet Version",
    total_operation: "Total Operations",
    total_step: "Total Step",
    total_count: "Total Count",
    current_step: "Current Step",
    moreDetail: "More Details",
    signed_tx: "Signed Transaction",
    batchIdError: "Enter Batch ID",
    operationStatusError: "Please select a Operation Status",
    operationTypeError: "Please select a Operation Type",
    clientData: "Client Data",
    request_time: "Request Time",
    transaction_batch_id: "Transaction Batch ID",
    operationDetails: "Operation Details",
    moreDetails: "More Details",
    approval: "Approval",
    view: "View",
    download: "Download",
    sign: "Sign",
    approve: "Approve",
    reject: "Reject",
    search: "Search",
    reset: "Reset",
    status: "Status",
    last_modified_date: "Last Modified Time",
    callback_status: "Callback Status",
    totalAmount: "Total Amount",
    cancel: "Cancel",
    transactionApproved: "Transaction Approved",
    transactionRejected: "Transaction Rejected",
    uploadSuccess: "Upload Success",
    uploadFailed: "Upload Failed",
    fileUpload: "Upload File",
    uploadBtn: "Click here to upload",
    uploadFileDialog:
      "Reminder: System will automatically performs broadcast after signed",
    creationTimeStart: "Creation Time Start",
    creationTimeEnd: "Creation Time End",
    startDate: "Start Date",
    endDate: "End Date",
    remark: "Remarks",
    action: "Actions",
    phBatch_id: "Please enter Batch ID",
    phChain_name: "Please select Chain Name",
    phAsset_name: "Please select Currency",
    phOperation_type: "Please select Operation Type",
    phStatus: "Please select Status",
    export: "Export",
    approveOrReject: "Approve/Reject",
    last_modified_time: "Last Modified Time",
    creator_type: "Creator Type",
    phCreator_type: "Creator type",
    error_reason: "Error Reason",
    approveAndRejectButton: "Approve/Reject",
    miner_fee_currency: "Currency of Miner Fee",
    miner_fee: "Miner Fee",
    actual_amount: "Actual Amount",
  },
  message: {
    table: "table",
    header: "Message Management",
    searchFilter: "Search Filter",
    receivedTimeStart: "Received Time Start",
    receivedTimeEnd: "Received Time End",
    lastAttemptTimeStart: "Last Attempt Time Start",
    lastAttemptTimeEnd: "Last Attempt Time End",
    dataTypes: "Data Type",
    callbackStatuses: "Callback Status",
    client_id: "Client ID",
    wallet_name: "Wallet Name",
    txHashes: "Transaction Hash",
    merchantOrderIds: "Merchant Order Ids",
    attemptCountGte: "Attempt Count Greater Than",
    search: "Search",
    reset: "Reset",
    hasReset: "has been reset",
    id: "ID",
    batch_id: "Batch ID",
    batch_ids: "Batch ID",
    operation_id: "Operation ID",
    merchant_id: "Merchant ID",
    chain_name: "Chain Name",
    tx_hash: "Transaction Hash",
    error_reason: 'Error Reason',
    merchant_order_id: "Merchant Order Id",
    status: "Status",
    callback_batch: "Callback Batch",
    callback_seq: "Callback Sequence",
    data_type: "Data Type",
    callback_base_url: "Callback Base URL",
    callback_url: "Callback URL",
    callback_status: "Callback Status",
    attempt_count: "Attempt Count",
    date_received: "Received Date",
    date_last_callback_attempt: "Last Callback Attempt Date",
    startDate: "Start Date",
    endDate: "End Date",
    resetConfirmation: "Confirm to reset attempt count?",
    confirm: "Confirm",
    cancel: "Cancel",
    phDataTypes: "Please select Data Type",
    phCallbackStatuses: "Please select Callback Status",
    phClient_id: "Please enter Client ID",
    phWallet_name: "Please enter Wallet Name",
    phTxHashes: "Please enter Transaction Hash",
    phMerchantOrderIds: "Please enter Merchant Order ID",
    phAttemptCountGte: "Please enter Attempt Count",
    phBatchId: "Please enter Batch ID (multiple selection)",
    reminder: "Please click SEARCH for searching records",
    noRow: "No relevant record exists",
  },
  enumConstants: {
    eq: "Equal",
    gt: "Greater than",
    gte: "Greater than or equal to",
    lt: "Less than",
    lte: "Less than or equal to",
    "": "",
    timezone0: "+0",
    timezone1: "+1",
    timezone2: "+2",
    timezone3: "+3",
    timezone4: "+4",
    timezone5: "+5",
    timezone6: "+6",
    timezone7: "+7",
    timezone8: "+8",
    timezone9: "+9",
    timezone10: "+10",
    timezone11: "+11",
    timezone12: "+12",
    timezone13: "-11",
    timezone14: "-10",
    timezone15: "-9",
    timezone16: "-8",
    timezone17: "-7",
    timezone18: "-6",
    timezone19: "-5",
    timezone20: "-4",
    timezone21: "-3",
    timezone22: "-2",
    timezone23: "-1",
    en: "English",
    zh_cn: "简中",
    Bound: "Bound",
    Unbound: "Unbound",
    PendingToBeBound: "Pending to be binded",
    PendingProposalApproval: "Pending",
    PendingForApproval: "Pending For Approval",
    YubiKeyRegistration: "Register YubiKey",
    UpdateYubiKey: "Change YubiKey",
    RebindYubiKey: "Rebind YubiKey",
    UnbindYubiKey: "Unbind YubiKey",
    EnableApprovalPermission: "Enable Approval Permission",
    DisableApprovalPermission: "Disable Approval Permission",
    CreateRecord: "Create Record",
    Edit: "Edit",
    Approve: "Approve",
    Delete: "Delete",
    Rescan: "Rescan",
    Re_Broadcast: "Rebroadcast",
    auto: "Auto",
    optional: "Optional",
    YubiKeyRegister: "YubiKey Register",
    login: "Login",
    Login: "Login",
    "Internal Server Error": "Internal Server Error",
    "Login Token Error": "Login Token Error",
    "Invalid Login Token": "Invalid Login Token",
    "Database Error": "Database Error",
    "Network Error": "Network Error",
    "Invalid Argument": "Invalid Argument",
    "Invalid Login Token Type": "Invalid Login Token Type",
    SubmitFailed: "SubmitFailed",
    SignFailed: "SignFailed",
    Failed: "Failed",
    Reject: "Reject",
    Requested: "Requested",
    Submitted: "Submitted",
    Executing: "Executing",
    Completed: "Completed",
    "Partial Completed": "Partial Completed",
    Signed: "Signed",
    Deleted: "Deleted",
    Disabled: "Disabled",
    disable: "Disable",
    unbind: "Unbind",
    Unassigned: "Unassigned",
    enable: "Enable",
    Enabled: "Enabled",
    ClientWallet: "Client Wallet",
    "External Wallet": "External Wallet",
    "Client Wallet": "Client Wallet",
    "Hot Wallet": "Hot Wallet",
    "Invoker Wallet": "Invoker Wallet",
    "Sweep Dest Wallet": "Sweep Dest Wallet",
    "Address Book": "Address Book",
    "Settlement Wallet": "Settlement Wallet",
    ExternalWallet: "External Wallet",
    HotWallet: "Hot Wallet",
    InvokerWallet: "Invoker Wallet",
    SweepDestWallet: "Sweep Dest Wallet",
    AddressBook: "Address Book",
    SettlementWallet: "Settlement Wallet",
    "No Related Wallet Address": "No Related Wallet Address",
    no_related_wallet_address: "No Related Wallet Address",
    no_related_wallet_type_address: "No Related %{walletType} Address",
    "No Related External Wallet Address": "No Related External Wallet Address",
    "No Related Client Wallet Address": "No Related Client Wallet Address",
    "No Related Hot Wallet Address": "No Related Hot Wallet Address",
    "No Related Invoker Wallet Address": "No Related Invoker Wallet Address",
    "No Related Sweep Dest Wallet Address":
      "No Related Sweep Dest Wallet Address",
    "No Related Address Book Address": "No Related Address Book Address",
    "No Related Settlement Wallet Address":
      "No Related Settlement Wallet Address",
    "External Miner Fee": "External Miner Fee",
    In: "In",
    Out: "Out",
    IN: "In",
    OUT: "Out",
    Yes: "Yes",
    yes: "Yes",
    No: "No",
    no: "No",
    NO_DIRECTION: "None",
    Adjustment: "Adjustment",
    ConfirmedFail: "ConfirmedFail",
    Unconfirmed: "Unconfirmed",
    ConfirmedSuccess: "ConfirmedSuccess",
    Invalid: "Invalid",
    "External Transfer": "External Transfer",
    "Client Deposit": "Client Deposit",
    "Hot Wallet Deposit": "Hot Wallet Deposit",
    "Invoker Deposit": "Invoker Deposit",
    LOSS10: "LOSS10",
    CAUTION11: "CAUTION11",
    "Sweep to Hot Wallet": "Sweep to Hot Wallet",
    CAUTION13: "CAUTION13",
    Sweep: "Sweep",
    CAUTION15: "CAUTION15",
    CAUTION16: "CAUTION16",
    MinerFee: "Miner Fee",
    Withdraw: "Withdraw",
    "Withdraw Deposit": "Withdraw Deposit",
    "Hot Wallet Transfer": "Hot Wallet Transfer",
    CAUTION23: "CAUTION23",
    CAUTION24: "CAUTION24",
    "Withdraw Account Activation": "Withdraw Account Activation",
    Settlement: "Settlement",
    "Withdraw Fee": "Withdraw Fee",
    LOSS30: "LOSS30",
    "Client Wallet Topup": "Client Wallet Topup",
    "Hot Wallet Topup": "Hot Wallet Topup",
    "Invoker Transfer": "Invoker Transfer",
    CAUTION34: "CAUTION34",
    CAUTION35: "CAUTION35",
    "Sweep Account Activation": "Sweep Account Activation",
    "Topup Fee": "Topup Fee",
    PrepareRetry: "PrepareRetry",
    Rejected: "Rejected",
    Approved: "Approved",
    Pending: "Pending",
    PendingForEnabled: "Pending For Approval",
    ReceivedAll: "ReceivedAll",
    Prepared: "Prepared",
    Retry: "Retry",
    Incompleted: "Incompleted",
    VerifyFailed: "VerifyFailed",
    Broadcasted: "Broadcasted",
    Verified: "Verified",
    true: "True",
    false: "False",
    FastConfirmedSuccess: "Fast Confirmed Success",
    BroadcastFailed: "Broadcast Failed",
    FastConfirmedFail: "Fast Confirmed Fail",
    Deposit: "Deposit",
    Desposit: "Deposit",
    HotWalletDepositStatus: "HotWalletDepositStatus",
    Operation: "Operation",
    Operationbatch: "OperationBatch",
    Error: "Error",
    PushErr: "PushErr",
    undefined: "",
    CreatorUnknown: "",
    CreatorPortal: "On Portal",
    CreatorApi: "API",
    //Function Name / AuditLog
    asset_list: "Asset List",
    batch_sweep: "Batch Sweep",
    batch_withdraw: "Batch Withdraw",
    operation_transaction_records: "Operation Transaction Reccords",
    merchant_operation: "Merchant Operation",
    withdraw_approval_setting: "Withdraw Approval Setting",
    merchant_wallet_management: "Merchant Wallet Management",
    client_wallet_management: "Client Wallet Management",
    wallet_balance_adjustment: "Wallet Balance Adjustment",
    message_management: "Message Management",
    block_rescan: "Rescan",
    re_broadcast: "Rebroadcast",
    yubikey_register: "YubiKey Register",
    on_Chain_balance_checkpoint: "On-Chain Balance Checkpoint",
    //OperationType /Audit Log //endpointList
    Create_record: "Create Record",
    Re_broadcast: "Rebroadcast",
    YubiKey_register: "YubiKey Register",
    Switch_to_manual_approval_process: "Switch to Manual Approval Process",
    ledger_deposit_record: "Ledger Deposit Record",
    ledger_withdraw_request: "Ledger Withdraw Request",
    ledger_transfer_request: "Ledger Transfer Request",
    ledger_client_management: "Ledger Client Management",
    LedgerDeposit: "Ledger Deposit",
    LedgerWithdraw: "Ledger Withdraw",
    LedgerTransfer: "Ledger Transfer",
    LedgerTransaction: "Ledger Transaction",
    depositStatus: "Deposit Status",
    operationStatus: "Operation Status",
    operationBatchStatus: "Operation Batch Status",
    blockNumber: "Block Number",
    hotwalletDepositStatus: "Hot Wallet Deposit Status",
    // Message Management Filter Option
    ledgerWithdrawRequest: "Ledger Withdraw",
    ledgerTransferRequest: "Ledger Transfer",

    ledgerTransaction: "Ledger Transaction",
    ledgerDeposit: "Ledger Deposit",
    PartialCompleted: "Partial Completed",
    Stucking: "Stucking",
    user_approval_management: "User Approval Management",
    confirmFailure: "Confirm Failure",
    AddAddress: "Add Address",
    DeleteAddress: "Delete Address",
    account_setting: "Account Setting",
    address_generation: "Address Generation",
    wallet_management: "Wallet Management",

    "Add Record": "Add Record",
    "Edit Record": "Edit Record",
    "Delete Record": "Delete Record",
    Unused: "Unused",
    Used: "Used",
    system: "SYSTEM",
    house: "HOUSE",
    client: "CLIENT",
    System: "SYSTEM",
    House: "HOUSE",
    Client: "CLIENT",
    Transfer: "Transfer",
    WithdrawFee: "Withdraw Fee",
    TransferFee: "Transfer Fee",
    WithdrawRefund: "Withdraw Refund",
    WithdrawFeeRefund: "Withdraw Fee Refund",
    FreezeBalance: "Freeze Balance",
    UnFreezeBalance: "Unfreeze Balance",
    risk_acceptant: "Acceptant Risk Level",
    NoData: "-2 No Data",
    NoAssessment: "-1 No Assessment",
    NoRiskDetected: "0 No Risk Detected",
    Info: "1 Info",
    Caution: "2 Caution",
    Medium: "3 Medium",
    High: "4 High",
    Critical: "5 Critical",
    ConfirmFail: "Confrim Fail",
    Portal: "On Portal",
    Api: "API",
    initial: "Initial",
    Execute: "Executing",
    ExecuteSuccess: "Execute Success",
    Refund: "Refund",
    RefundSuccess: "Refund Success",
    AwaitRiskLevel: "Await Risk Level",
    Confirmed: "Confirmed Success",
    ConfirmFailed: "Confirm Failed",
    FastConfirmed: "Fast Confirmed Success",
    includeNoRiskWallet: "Only Include Transactions with No Risk Level Data",
    notIncludeNoRiskWallet: "Not Include Transactions with No Risk Level Data",
    Frozen: "Frozen",
    Unfrozen: "Unfrozen",
    timeout: "Timeout",
    httpErrorCode: 'Request failed with status code'
  },
  enumWithDrawApprovalSetting: {
    pending: "Pending",
    rejected: "Rejected",
    approved: "Approved",
  },
  callbackSetting: {
    block_number: "Block No.",
    deposit_status: "Deposit",
    Hot_Wallet_DepoSit_Status: "Hot Wallet Deposit Status",
    operation_status: "Operation",
    operation_batch_status: "Operation Batch",
    ledger_withdraw_request: "Ledger Withdraw",
    ledger_transfer_request: "Ledger Transfer",
    ledger_transaction: "Ledger Transaction",
    ledger_deposit: "Ledger Deposit",
    depositStatus: "Deposit Status",
    operationStatus: "Operation Status",
    operationBatchStatus: "Operation Batch Status",
    blockNumber: "Block Number",
    hotwalletDepositStatus: "Hot Wallet Deposit Status",
    ledgerWithdrawRequest: "Ledger Withdraw Request",
    ledgerTransferRequest: "Ledger Transfer Request",
    ledgerTransaction: "Ledger Transaction",
    ledgerDeposit: "Ledger Deposit",
    table: "table",
    merchant_id: "Merchant ID",
    data_type: "Data Type",
    send: "Send",
    last_modified_by_id: "Last Modified",
    date_last_modified: "Last Modified Date",
    activate: "Activate",
    disable: "Disable",
    Confirm: "Confirm",
    Return: "Return",
    Edit: "Edit",
    confirm: "Updated",
    Error: "Error",
    cancel: "Cancel",
    callbackSettingToolTip: "Callback Setting",
  },
  merchantOperationModel: {
    paymentWallet: "Payment Wallet",
    approve: "Approval",
    sign: "Sign",
    callback: "Callback",
    sweep: "Sweep",
    withdraw: "Withdraw",
    automate: "Auto",
    yes: "Yes",
    no: "No",
    manual: "Manual",
    return: "Return",
  },
  withdrawSetting: {
    merchantName: "Merchant Name",
    merchantWithdrawStatus: "Merchant Withdraw Status",
    enable: "Enabled",
    disable: "Disabled",
    switchWithdrawStatus: "Switch Merchant Withdraw Status",
    switchWithdrawStatusReminder: "Merchant Withdraw Status switch to",
    updateSettingSuccess: "Update Withdraw Setting Successfully",
    updateSettingFailed: "Update Withdraw Setting Failed",
  },
  totalWalletBalance: {
    id: "ID",
    table: "Table",
    wallet_type: "Wallet Type",
    chain_name: "Chain Name",
    asset_name: "Currency",
    balance: "Total Balance",
    last_modified_date: "Last Modified Time",
    merchant_id: "Merchant ID",
    phWallet_type: "Please select Wallet Type",
    phChain_name: "Please select Chain Name",
    phAsset_name: "Please select Currency",
    search: "Search",
    reset: "Reset",
    export: "Export",
  },
  auditLog: {
    table: "Table",
    request_time: "Request Time",
    function_name: "Function Name",
    operation: "Operation",
    batch_id: "Batch ID",
    response_time: "Response Time",
    operator: "Operator",
    serial_no: "Serial No.",
    startDate: "Start Date",
    endDate: "End Date",
    phFunction_name: "Please Select Function Name",
    phOperation: "Please Select Operation",
    phBatch_id: "Please enter Batch Id",
    phOperator: "Please enter Operator",
    phSerial_no: "Please enter Serial No",
    api_url: "API URL",
    viewDetailsOperation: "Operation",
    search: "Search",
    reset: "Reset",
    portal_operation_tab: "Portal Operation",
    api_in_operation_tab: " API IN",
    api_out_operation_tab: "API OUT",
    cancel: "Cancel",
    details: "Details",
    requesHeader: "Request Header",
    requestBody: "Request Body",
    requestResp: "Request Response",
    resposneTime: "Response Time",
    relatedLog: "Related Log",
    view: "View Details",
    res_date: "Request Time",
  },
  assetList: {
    table: "Table",
    asset_name: "Currency",
    chain_name: "Chain Name",
    decimals: "Decimals",
    withdraw_status: "Withdraw Status",
    last_operated_by: "Last Operated By",
    last_operation_time: "Last Operation Time",
    phChain_name: "Please Select Chain Name",
    phWithdraw_status: "Please Select Withdraw Status",
    phAsset_name: "Please Select Currency",
    search: "Search",
    reset: "Reset",
    operation: "Operation",
    Disable: "Disabled",
    Enable: "Enabled",
    withdrawStatus: "Withdraw Status",
    confirm: "Confirm",
    confirmed: "Confirmed",
    cancel: "Cancel",
    edit: "Edit",
    statusHantChange: "Please Change Withdraw Status",
    view: "",
    approve: "",
    download: "",
    sign: "",
    push_deposit_message: "Push Deposit Message",
    ph_push_deposit_message: "Please Select Push Deposit Message",
  },
  AddressGeneration: {
    chain_name: "Chain Name",
    wallet_types: "Wallet Type",
    wallet_version: "Wallet Version",
    sum_addr_generated: "Total No. of Address Generated",
    addr_unused_count: "No. of Unused Address ",
    addr_used_count: "No. of Used Address",
    phChain_name: "Please select Chain Name",
    phWallet_type: "Please select Wallet Type",
    phNumOfAddress: "Please select No. of Address",
    address_generation: "Generate Address",
    import: "Upload File",
    cancel: "Cancel",
    confirm: "Confirm",
    uploadAddress: "Upload",
    Import: "Upload",
    success_generate_txt: "File downloaded",
    success: "Success",
    import_success: "Upload success",
    import_failed: "Upload Failed",

    click_here_import: "Click here to upload",
  },
  NonceTool: {
    nonceTab: "Nonce",
    priorityListTab: "Priority List",
    status: "Status",
    ph_status: "Please select Status",
    sending_address: "Sending Address",
    ph_sending_address: "Please enter Sending Address",
    batch_id: "Batch ID",
    ph_batch_id: "Please enter Batch ID",
    chain_name: "Chain Name",
    ph_chain_name: "Please select Chain Name",
    step_sequence: "Step Sequence",
    transaction_sequence: "Transaction Sequence",
    nonce: "Nonce",
    operation_type: "Operation Type",
    transaction_hash: "Transaction Hash",
    creation_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    insert: "Insert",
    back: "Back",
    next: "Next",
    confirm: "Confirm",
    chain_name_confirm: "Chain Name",
    sending_address_confirm: "Sending Address",
    next_nonce: "Next Nonce",
    last_chain_nonce: "Latest Chain Nonce",
    input_range: "Input Range",
    ph_nonce: "Please enter Nonce",
    allFieldsShouldBeFilled: "Please fill all the fields",
    deleted: "Deleted",
    delete: "Delete",
    operation: "Operation",
    delete_dialog: "Confirm to delete Nonce insert record?",
    insert_details: "Insert Details",
    deleted_success_alert: "Nonce insert record deleted",
    deleted_fail_alert: "Fail to delete Nonce insert record",
    inserted_success_alert: "Nonce inserted successfully",
    inserted_fail_alert: "Failed to insert Nonce",
    insert_nonce_error: "Please insert Nonce within input range",
    next_nonce_notice: "(Next Nonce to be used in the system)",
    last_nonce_notice: "(Latest Nonce on chain)",
    select_chain_name_first: "Please select chain name",
    id: "ID",
    noRow: "No relevant record exists",
    reminder: "Please enter Chain Name and click SEARCH for searching records",
    no_input_range: "No input range can be inserted",
  },
  utxoManagement: {
    syncSuccessful:
      "The UTXO of related wallet address has been synchronized with the chain",
    updatedFailed: "Failed to update UTXO",
    utxoModal: "UTXO Sync",
    btc_utxo_tab: "BTC Series",
    ada_utxo_tab: "ADA",
    create_time: "Creation Time",
    last_modified_time: "Last Modified Time",
    wallet_address: "Wallet Address",
    tx_hash: "Transaction Hash",
    asso_tx_hash: "Associated Transaction Hash",
    ph_wallet_address: "Please enter Wallet Address",
    ph_asso_tx_hash: "Please enter Transaction Associated Hash",
    ph_tx_hash: "Please enter Transaction Hash",
    phStartTime: "Start Time",
    phEndTime: "End Time",
    phChain_name: "Please select Chain Name",
    chain_name: "Chain Name",
    currency: "Currency",
    vout: "Output Index (Vout)",
    spent_tx_hash: "Spent to (Transaction Hash)",
    status: "Status",
    creation_time: "Creation Time",
    delete: "Delete",
    helperDelText:
      "To delete relevant UTXO, search for the relevant transaction hash",
    utxo_id: "UTXO ID",
    alertIfNotHotWallet:
      "The wallet type of the inputted wallet address must be Hot Wallet",
    copied: "Copied!",
    successDeleteAlert:
      "Related UTXO and its following relationships of UTXO are successfully deleted",
    failed_to_delete_utxo: "Failed to delete UTXO",
    reminder:
      "Please select Chain Name and enter Wallet Address for searching records",
    noRow: "No relevant record exists",
    unused: "Available",
    used: "Used",
    invalid: "Invalid",
    mustInputChainName: "Please select Chain Name",
    mustInputWalletAddress: "Please enter Wallet Address",
    confirmDelete: "Confirm to Delete?",
    sync_utxo: "Sync UTXO",
    Delete: "Delete",
    undefined: "undefined",
    Adareminder: "Please enter Wallet Address for searching records",
    inputChainNameAddress:
      "Please select Chain Name and enter Wallet Address for searching records",
    syncAlert: "Please select Chain Name and enter Wallet Address",
  },
  resubmit: {
    tableTab: "table",
    columns: {
      operation: "Operation",
      batch_id: "Batch ID",
      operation_type: "Operation Type",
      chain_name: "Chain Name",
      asset_name: "Currency",
      total_amount: "Total Amount",
      total_count: "Total Count",
      status: "Batch Status",
      created_by: "Created By",
      created_date: "Creation Time",
    },
    filter: {
      created_date: "Creation Time",
      operation_type: "Operation Type",
      chain_name: "Chain Name",
      asset_name: "Currency",
      batch_id: "Batch ID",
      phChain_name: "Please select Chain Name",
      phOperation_type: "Please select Operation Type",
      phAsset_name: "Please select Currency",
      phBatch_id: "Please enter Batch Id",
      reset: "Reset",
    },
    prompter: {
      title: "Confirm to resubmit?",
    },
    alert: {
      success: "Resubmit Success",
    },
    resubmitBtn: "Resubmit",
  },

  depositAndWithdrawalStatistics: {
    block_time: "Block Time",
    chain_name: "Chain Name",
    currency: "Currency",
    ph_chain_name: "Please select Chain Name",
    ph_currency: "Please select Currency (multiple selection)",
    deposit_number_of_transactions: "Deposit (Number of Transactions)",
    deposit_total_transaction_amount: "Deposit (Total Transaction Amount)",
    withdraw_number_of_transactions: "Withdraw (Number of Transactions)",
    withdraw_total_transaction_amount: "Withdraw (Total Transaction Amount)",
    block_time_warning: "Please select Block Time",
    block_time_from: "Block Time From",
    block_time_to: "Block Time To",
  },

  errorCode: errorCode.en,
};

export default json;
