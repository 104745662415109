import { useEffect, useState } from "react";
import { useTranslate } from "react-admin";

import {
  CallbackSettingDataType,
  CallbackStatus,
} from "@wallet-manager/node-types/dist/types/access/const/CallbackSetting";
import EnumRequestStatus from "@wallet-manager/node-types/dist/types/ledger/enums/LedgerRequestStatus";
import EnumOperationBatchStatus from "@wallet-manager/node-types/dist/types/postgres/const/OperationBatchStatus";
import EnumOperationStatus from "@wallet-manager/node-types/dist/types/postgres/const/OperationStatus";
import EnumTransactionStatus from "@wallet-manager/node-types/dist/types/postgres/const/TransferTransactionStatus";

import { getMessages, listFace } from "../../api/merchant";
import { useDatePicker } from "../../components/DatePicker";
import GeneralBtn from "../../components/GeneralBtn";
import { NodeTypesEnumMultiSelection } from "../../components/GeneralSelection";
import MpTextField from "../../components/MpTextField";
import {
  Box,
  Container,
  InputAdornment,
  SelectChangeEvent,
} from "../../components/MuiGenerals";
import { useTabs } from "../../hooks";
import { tableConfig } from "../../utils/constant";
import {
  findChainInfo,
  listMappingTransform,
  onlyAllowInteger,
  separateBatchStrings,
  toDisplayTime,
} from "../../utils/helper";
import { DivideLine, genField } from "../../utils/HelperComp";
import { customSx } from "../../utils/styling";
import { useZusDialogStore } from "../../zustand/store";
import MessageManagementList from "./MessageManagementList";
import { EnumMessageErrorReason } from "@wallet-manager/node-types/dist/src/access/dbModel/Enum/MessageErrorReason";

function MessageManagement() {
  const translate = useTranslate();
  const t = (key: string) => translate(`message.${key}`);
  const [isSearchBtnClick, setSearchBtnClick] = useState(false);
  const [list, setList] = useState<listFace>({ rows: [], count: 0 });
  const [page, setPage] = useState(0);
  const onNewList = (list: listFace) => {
    if (!list) return;
    setList(list);
  };
  // const tabsArr = [{ name: t("table"), value: "" }];
  // const { Tabs } = UseCallbackSettingTabs(tabsArr);
  const tabsArr = [{ name: t("table"), value: "" }];
  const { Tabs } = useTabs(tabsArr);
  return (
    <Box sx={customSx.layoutBox}>
      <Container disableGutters maxWidth={false}>
        <FilterBar
          setList={onNewList}
          setPage={setPage}
          page={page}
          isSearchBtnClick={isSearchBtnClick}
          setSearchBtnClick={setSearchBtnClick}
        />
      </Container>
      <DivideLine />
      <Container
        style={customSx.datagridContainer}
        maxWidth={false}
        disableGutters
      >
        <Tabs>
          <MessageManagementList
            list={list}
            page={page}
            setPage={setPage}
            listMapping={listMapping}
            isSearchBtnClick={isSearchBtnClick}
          />
        </Tabs>
      </Container>
    </Box>
  );
}

interface fieldsFace {
  clientIdsString: string;
  walletNameString: string;
  txHashesString: string;
  dataTypes: string[];
  callbackStatuses: string[];
  merchantOrderIdsString: string;
  dateReceivedFrom: string;
  dateReceivedTo: string;
  dateLastCallbackAttemptFrom: string;
  dateLastCallbackAttemptTo: string;
  attemptCountGte: string;
  batch_ids: string;
}

const initFields = {
  clientIdsString: "",
  walletNameString: "",
  txHashesString: "",
  dataTypes: [],
  callbackStatuses: [],
  merchantOrderIdsString: "",
  dateReceivedFrom: "",
  dateReceivedTo: "",
  dateLastCallbackAttemptFrom: "",
  dateLastCallbackAttemptTo: "",
  attemptCountGte: "",
  batch_ids: ""
};

function FilterBar(props: any) {
  const translate = useTranslate();
  const t = (key: string) => translate(`message.${key}`);
  const { setList, setPage, page, isSearchBtnClick, setSearchBtnClick } = props;
  const [fields, setFields] = useState<fieldsFace>(initFields);
  const DateObj = {
    Received: useDatePicker(),
    Attempt: useDatePicker(),
  };
  const getParams = () => {
    const {
      clientIdsString,
      walletNameString,
      txHashesString,
      merchantOrderIdsString,
      batch_ids,
      ...rest
    } = fields;
    return {
      ...rest,
      dateReceivedFrom: DateObj.Received.start,
      dateReceivedTo: DateObj.Received.end,
      dateLastCallbackAttemptFrom: DateObj.Attempt.start,
      dateLastCallbackAttemptTo: DateObj.Attempt.end,
      clientIds: separateBatchStrings(clientIdsString),
      walletNames: separateBatchStrings(walletNameString),
      txHashes: separateBatchStrings(txHashesString),
      merchantOrderIds: separateBatchStrings(merchantOrderIdsString),
      batch_ids: separateBatchStrings(batch_ids),
      page,
      pageSize: tableConfig.pageSize,
    };
  };

  const params = getParams();
  const [cacheParams, setCacheParams] = useState(params);
  const zusDialog = useZusDialogStore();
  useEffect(() => {
    if (!isSearchBtnClick) return;
    const fetchFn = async () => {
      zusDialog.openExtra("loadingDialog");
      const res = await getMessages({ ...cacheParams, page });
      zusDialog.closeExtra();
      if (!res) return;
      setList(res);
    };
    fetchFn();
  }, [page, cacheParams, isSearchBtnClick]);
  const onArraySelectChange = (e: SelectChangeEvent<string[]>) => {
    const { name, value } = e.target;
    const newValue = typeof value === "string" ? value.split(",") : value;
    return setFields((fields) => ({ ...fields, [name]: newValue }));
  };
  const onSearch = async () => {
    setSearchBtnClick(true);
    setPage(0);
    setCacheParams(params);
  };
  const onReset = () => {
    setFields(initFields);
    DateObj.Received.clearDate();
    DateObj.Attempt.clearDate();
  };
  const onChange =
    (type: string) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = e.target.value;
      const name = e.target.name;
      const resp = name === "attemptCountGte" ? onlyAllowInteger(value) : true;
      if (!resp) {
        return;
      }

      setFields((fields) => ({ ...fields, [type]: value }));
    };
  const genEndAdornment = (array: any[]) => ({
    endAdornment: (
      <InputAdornment position="end">{array.length}</InputAdornment>
    ),
  });
  const F = genField({ t }, [
    [
      "date_received",
      <Box sx={customSx.dateSelect}>
        <DateObj.Received.Picker type="dateTime" />
      </Box>,
    ],
    [
      "date_last_callback_attempt",
      <Box sx={customSx.dateSelect}>
        <DateObj.Attempt.Picker type="dateTime" />
      </Box>,
    ],
    [
      "dataTypes",
      <NodeTypesEnumMultiSelection
        label={t("phDataTypes")}
        value={fields.dataTypes}
        onChange={(dataTypes) => setFields((f) => ({ ...f, dataTypes }))}
        enumData={CallbackSettingDataType}
      />,
    ],

    [
      "callbackStatuses",
      <NodeTypesEnumMultiSelection
        label={t("phCallbackStatuses")}
        value={fields.callbackStatuses}
        onChange={(callbackStatuses) =>
          setFields((f) => ({ ...f, callbackStatuses }))
        }
        enumData={CallbackStatus}
      />,
    ],
    // [
    //   "wallet_name",
    //   <MpTextField
    //     label={t("phWallet_name")}
    //     value={fields.walletNameString}
    //     onChange={onChange("walletNameString")}
    //     InputProps={genEndAdornment(params.walletNames)}
    //   />,
    // ],
    [
      "client_id",
      <MpTextField
        label={t("phClient_id")}
        value={fields.clientIdsString}
        onChange={onChange("clientIdsString")}
        InputProps={genEndAdornment(params.clientIds)}
      />,
    ],
    [
      "txHashes",
      <MpTextField
        label={t("phTxHashes")}
        value={fields.txHashesString}
        onChange={onChange("txHashesString")}
        InputProps={genEndAdornment(params.txHashes)}
      />,
    ],
    [
      "merchantOrderIds",
      <MpTextField
        label={t("phMerchantOrderIds")}
        value={fields.merchantOrderIdsString}
        onChange={onChange("merchantOrderIdsString")}
        InputProps={genEndAdornment(params.merchantOrderIds)}
      />,
    ],
    [
      "attemptCountGte",
      <MpTextField
        name="attemptCountGte"
        label={t("phAttemptCountGte")}
        type="string"
        value={fields.attemptCountGte}
        onChange={onChange("attemptCountGte")}
      />,
    ],
    [
      "batch_ids",
      <MpTextField
        label={t("phBatchId")}
        value={fields.batch_ids}
        onChange={onChange("batch_ids")}
        InputProps={genEndAdornment(params.batch_ids)}
        />
    ]
  ]);
  return (
    <>
      <Box sx={customSx.gridFilter} className="gridFilter">
        {F.date_received}
        {F.date_last_callback_attempt}
        {F.dataTypes}
        {F.callbackStatuses}
        {F.client_id}
        {F.wallet_name}
        {F.txHashes}
        {F.merchantOrderIds}
        {F.attemptCountGte}
        {F.batch_ids}
      </Box>

      <Box sx={customSx.filterB}>
        <GeneralBtn label="search" onClick={onSearch} />
        <GeneralBtn label="reset" onClick={onReset} />
      </Box>
    </>
  );
}
export default MessageManagement;

const listMapping = (
  which: "key" | "name",
  array: any[],
  config: any
): any[][] => {
  const { translate } = config;
  const t = (key: string) => translate(`message.${key}`);
  const toDisplayStatus = (type: string, statusId: string) => {
    const enumStatus = {
      [CallbackSettingDataType.depositStatus]: EnumTransactionStatus,
      [CallbackSettingDataType.hotwalletDepositStatus]: EnumTransactionStatus,
      [CallbackSettingDataType.operationStatus]: EnumOperationStatus,
      [CallbackSettingDataType.operationBatchStatus]: EnumOperationBatchStatus,
      [CallbackSettingDataType.ledgerDeposit]: EnumRequestStatus,
      [CallbackSettingDataType.ledgerTransferRequest]: EnumRequestStatus,
      [CallbackSettingDataType.ledgerWithdrawRequest]: EnumRequestStatus,
    };
    const statuses = enumStatus[type as keyof typeof enumStatus];
    if (!statuses) return statusId;
    const status = statuses[Number(statusId)];
    return status ?? statusId;
  };
  const transformStatus = (item: any) => {
    if (!toDisplayStatus(item.data_type, item.status)) {
      return "";
    }
    return translate(
      `enumConstants.${toDisplayStatus(item.data_type, item.status)}`
    );
  };

  const translateErrorMessage = (errorReason: any) => {
    if(!errorReason) return "" 

    const { reason } = errorReason;

    const translatedMessage = translate(`enumConstants.${EnumMessageErrorReason[reason]}`)

    switch (reason) {
      case EnumMessageErrorReason.timeout: 
        return translatedMessage;
      case EnumMessageErrorReason.httpErrorCode:
        return `${translatedMessage} ${errorReason.error_code}`;
      case EnumMessageErrorReason.fromMechant:
        return errorReason.error_response;
    }
  }

  const res = array.map((item: any) => {
    const displayDatatypeEnum = Object.fromEntries(
      Object.entries(CallbackSettingDataType).map(([key, value]) => [
        value,
        key,
      ])
    );

    const mappedResult = [
      ["id", t("id"), item.id],
      ["batch_id", t("batch_id"), item.batch_id],
      ["merchant_order_id", t("merchant_order_id"), item.merchant_order_id],
      ["operation_id", t("operation_id"), item.operation_id],
      [
        "data_type",
        t("data_type"),
        translate(`enumConstants.${displayDatatypeEnum[item.data_type]}`),
      ],
      ["status", t("status"), transformStatus(item)],
      ["client_id", t("client_id"), item.client_id],
      // ["wallet_name", t("wallet_name"), item.wallet_name],
      [
        "chain_name",
        t("chain_name"),
        findChainInfo(String(item.chain_type), item.chain_id)?.name,
      ],
      ["callback_batch", t("callback_batch"), item.callback_batch],
      ["callback_seq", t("callback_seq"), item.callback_seq],
      ["callback_base_url", t("callback_base_url"), item.callback_base_url],
      ["attempt_count", t("attempt_count"), item.attempt_count],
      [
        "callback_status",
        t("callback_status"),
        item.callback_status &&
          CallbackStatus[item.callback_status as keyof typeof CallbackStatus],
      ],
      ["tx_hash", t("tx_hash"), item.tx_hash],
      ["error_reason", t("error_reason"), translateErrorMessage(item.error_reason)],
      ["date_received", t("date_received"), toDisplayTime(item.date_received)],
      [
        "date_last_callback_attempt",
        t("date_last_callback_attempt"),
        toDisplayTime(item.date_last_callback_attempt),
      ],
    ];
    return mappedResult;
  });
  const output = res.map(listMappingTransform(which));
  return output;
};
